/*!
 * Daemonite Material UI 4.6.0 - RC1 (https://djibe.github.io/material/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Material UI by djibe, Maya, Geoff Bowers and Alex Skinner
 */
:root {
  --amber: #ffc107;
  --blue: #2196f3;
  --blue-grey: #607d8b;
  --brown: #795548;
  --cyan: #00bcd4;
  --deep-orange: #ff5722;
  --deep-purple: #673ab7;
  --green: #4caf50;
  --grey: #9e9e9e;
  --indigo: #3f51b5;
  --light-blue: #03a9f4;
  --light-green: #8bc34a;
  --lime: #cddc39;
  --orange: #ff9800;
  --pink: #e91e63;
  --purple: #9c27b0;
  --red: #f44336;
  --teal: #009688;
  --yellow: #ffeb3b;
  --primary: #6200ee;
  --primary-dark: #3700b3;
  --primary-light: #bb86fc;
  --secondary: #03dac6;
  --secondary-dark: #018786;
  --secondary-light: #66fff8;
  --danger: #b00020;
  --danger-dark: #d32f2f;
  --danger-light: #c51162;
  --info: #714cfe;
  --info-dark: #021aee;
  --info-light: #d2c2fd;
  --success: #61d800;
  --success-dark: #41c300;
  --success-light: #c6f68d;
  --warning: #ff8d00;
  --warning-dark: #ee6002;
  --warning-light: #ffc77d;
  --dark: #424242;
  --dark-dark: #212121;
  --dark-light: #757575;
  --light: #f5f5f5;
  --light-dark: #e0e0e0;
  --light-light: #fafafa;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", system-ui, -apple-system, "Segoe UI", "Helvetica Neue", "Arial", "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: "Roboto Mono", "SFMono-Regular", "Menlo", "Monaco", "Consolas", "Liberation Mono", "Courier New", monospace;
  --font-family-material-icons: "Material Icons", "Material Icons Outlined", "Material Icons Two Tone", "Material Icons Round", "Material Icons Sharp";
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  text-align: left;
  text-align: start;
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", system-ui, -apple-system, "Segoe UI", "Helvetica Neue", "Arial", "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

[dir='rtl'] body {
  text-align: right;
  text-align: start;
}

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

[id] {
  scroll-margin-top: 2ex;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  border-bottom: 0;
  cursor: help;
  text-decoration: underline;
  text-decoration: underline dotted;
  text-decoration-skip-ink: none;
}

address {
  font-style: normal;
  line-height: inherit;
}

address,
dd,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

dt {
  font-weight: 600;
}

dd {
  margin: 1rem 0;
}

dd:not([class*='col']) {
  padding-left: 2.5rem;
}

dd:not([class*='col']) > ol,
dd:not([class*='col']) > ul {
  padding-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 500;
}

small {
  font-size: 75%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  background-color: transparent;
  color: #6200ee;
  text-decoration: none;
}

a:hover {
  color: #6200ee;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: "Roboto Mono", "SFMono-Regular", "Menlo", "Monaco", "Consolas", "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
  user-select: all;
}

figure {
  margin: 0 0 1rem;
}

img {
  border-style: none;
  vertical-align: middle;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  text-align: left;
  text-align: start;
  border-top: 1px solid #e1e1e1;
  caption-side: bottom;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.875rem;
  letter-spacing: 0.007143em;
  line-height: 1.375rem;
  padding: 1.09375rem 1rem;
}

[dir='rtl'] caption {
  text-align: right;
  text-align: start;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label, legend {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.033333em;
  line-height: 1.25rem;
  color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  user-select: none;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role='button'] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select[multiple], select[size] {
  overflow: auto;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

.btn:not(.disabled):not(:disabled), .card-link:not(.disabled):not(:disabled),
input:enabled + label:hover,
input:enabled + legend:hover {
  cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

legend {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.033333em;
  line-height: 1.25rem;
  float: left;
  margin-bottom: 0.5rem;
  padding: 0;
  width: 100%;
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

mark {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
}

@-ms-viewport {
  width: device-width;
}

dfn {
  font-style: italic;
}

::selection {
  background: rgba(98, 0, 238, 0.08);
}

.disabled:hover,
:disabled:hover {
  cursor: default;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: inherit;
  font-family: inherit;
  margin-bottom: 0.5rem;
}

h1,
.h1,
.display-1,
.typography-display-4,
.typography-headline-1 {
  font-size: 6rem;
  font-weight: 300;
  letter-spacing: -0.015625em;
  line-height: 6rem;
}

h2,
.h2,
.display-2,
.typography-display-3,
.typography-headline-2 {
  font-size: 3.75rem;
  font-weight: 300;
  letter-spacing: -0.008333em;
  line-height: 3.75rem;
}

h3,
.h3,
.display-3,
.typography-display-2,
.typography-headline-3 {
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 3.125rem;
}

h4,
.h4,
.display-4,
.typography-display-1,
.typography-headline-4 {
  font-size: 2.125rem;
  font-weight: 400;
  letter-spacing: 0.007353em;
  line-height: 2.5rem;
}

h5,
.h5,
.typography-headline,
.typography-headline-5 {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2rem;
}

h6,
.h6,
.lead,
.typography-headline-6,
.typography-title {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
}

.typography-subheading,
.typography-subtitle-1 {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.009375em;
  line-height: 1.75rem;
}

.typography-subtitle-2 {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.007143em;
  line-height: 1.375rem;
}

.typography-body-1 {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.03125em;
  line-height: 1.5rem;
}

.typography-body-2 {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.017857em;
  line-height: 1.25rem;
}

.typography-caption {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.033333em;
  line-height: 1.25rem;
}

.typography-overline {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.166667em;
  line-height: 2rem;
  text-transform: uppercase;
}

p {
  font-size: 1rem;
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.2em;
}

ol {
  list-style: decimal outside;
}

ol ol {
  list-style-type: lower-alpha;
}

ul {
  list-style: disc outside;
}

ul:not([class]) {
  padding-left: 2.5rem;
}

ul:not([class]) li {
  font-size: inherit;
  margin: 0.75rem 0;
  padding: 0;
}

li > ul > li {
  list-style-type: circle;
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
}

.list-inline {
  list-style: none;
  padding-left: 0;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.03125em;
  line-height: 1.5rem;
  border-left: 0.3125rem solid #6200ee;
  margin-bottom: 1rem;
  padding: 0 1rem;
}

.blockquote-footer {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.033333em;
  line-height: 1.25rem;
  color: rgba(0, 0, 0, 0.38);
  display: block;
  margin-top: 0.25rem;
}

.blockquote-footer::before {
  content: '\2014 \00A0';
}

.alert {
  border-radius: 4px;
  border: 0;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  margin-bottom: 1rem;
  padding: 1rem 1rem;
  position: relative;
}

.alert-link {
  color: inherit;
  font-weight: 500;
}

.alert-link:hover, .alert-link:focus, .alert-link:active {
  color: inherit;
}

.alert-dismissible {
  padding-right: 3.5rem;
}

.alert-dismissible .close {
  color: rgba(0, 0, 0, 0.38);
  padding: 1rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.alert-primary {
  background-color: rgba(98, 0, 238, 0.16);
  color: #4300a2;
}

.alert-secondary {
  background-color: rgba(3, 218, 198, 0.16);
  color: #028f81;
}

.alert-danger {
  background-color: rgba(176, 0, 32, 0.16);
  color: #640012;
}

.alert-info {
  background-color: rgba(113, 76, 254, 0.16);
  color: #3601fc;
}

.alert-success {
  background-color: rgba(97, 216, 0, 0.16);
  color: #3f8c00;
}

.alert-warning {
  background-color: rgba(255, 141, 0, 0.16);
  color: #b36300;
}

.alert-dark {
  background-color: rgba(66, 66, 66, 0.16);
  color: #1c1c1c;
}

.alert-light {
  background-color: rgba(245, 245, 245, 0.16);
  color: #cfcfcf;
}

.badge {
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
  font-size: inherit;
  font-weight: 500;
  justify-content: center;
  line-height: inherit;
  padding-right: 0.5em;
  padding-left: 0.5em;
  white-space: nowrap;
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn > .badge, .card-link > .badge,
.position-relative > .badge {
  border-radius: 0.6875rem;
  font-family: Roboto, sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  height: 1.375rem;
  letter-spacing: initial;
  min-width: 1.375rem;
  position: absolute;
  top: -0.6875rem;
}

.btn > .badge, .card-link > .badge,
i > .badge,
[class*='material-icons'] > .badge {
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}

.btn > .badge.badge-before, .card-link > .badge.badge-before,
i > .badge.badge-before,
[class*='material-icons'] > .badge.badge-before {
  right: auto;
  left: 0;
  transform: translate(-50%, -50%);
  transform-origin: 0% 100%;
}

.badge-pill {
  border-radius: 1em;
}

.badge-primary {
  background-color: #6200ee;
  color: white;
}

.badge-primary[href]:hover, .badge-primary[href]:focus, .badge-primary[href]:active {
  background-color: #3700b3;
  color: white;
  text-decoration: none;
}

.badge-secondary {
  background-color: #03dac6;
  color: white;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus, .badge-secondary[href]:active {
  background-color: #018786;
  color: white;
  text-decoration: none;
}

.badge-danger {
  background-color: #b00020;
  color: white;
}

.badge-danger[href]:hover, .badge-danger[href]:focus, .badge-danger[href]:active {
  background-color: #d32f2f;
  color: white;
  text-decoration: none;
}

.badge-info {
  background-color: #714cfe;
  color: white;
}

.badge-info[href]:hover, .badge-info[href]:focus, .badge-info[href]:active {
  background-color: #021aee;
  color: white;
  text-decoration: none;
}

.badge-success {
  background-color: #61d800;
  color: white;
}

.badge-success[href]:hover, .badge-success[href]:focus, .badge-success[href]:active {
  background-color: #41c300;
  color: white;
  text-decoration: none;
}

.badge-warning {
  background-color: #ff8d00;
  color: white;
}

.badge-warning[href]:hover, .badge-warning[href]:focus, .badge-warning[href]:active {
  background-color: #ee6002;
  color: white;
  text-decoration: none;
}

.badge-dark {
  background-color: #424242;
  color: white;
}

.badge-dark[href]:hover, .badge-dark[href]:focus, .badge-dark[href]:active {
  background-color: #212121;
  color: white;
  text-decoration: none;
}

.badge-light {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}

.badge-light[href]:hover, .badge-light[href]:focus, .badge-light[href]:active {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.breadcrumb {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}

.breadcrumb-item {
  align-items: center;
  color: #000000;
  display: flex;
  font-size: 1rem;
}

.breadcrumb-item.active {
  cursor: default;
}

.breadcrumb-item:only-child {
  padding: 0.375rem 0.5rem;
}

.breadcrumb-item a {
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 0.5rem;
  color: inherit;
  padding: 0.375rem 0.5rem;
  text-decoration: none;
}

@media (min-width: 576px) {
  .breadcrumb-item a {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .breadcrumb-item a {
    transition-duration: 280ms;
  }
}

.breadcrumb-item a:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.breadcrumb-item + .breadcrumb-item {
  margin-left: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-size: 1.5rem;
  line-height: 1.5rem;
  vertical-align: -0.362637em;
  font-family: 'Material Icons';
  font-family: var(--font-family-material-icons);
  font-feature-settings: 'liga';
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  color: rgba(0, 0, 0, 0.54);
  content: "chevron_right";
  display: inline-block;
  margin-right: 0;
}

.breadcrumb-item.active::before {
  margin-right: 0.5rem;
}

.carousel {
  position: relative;
}

.carousel:hover .carousel-control-next,
.carousel:hover .carousel-control-prev {
  opacity: 1;
}

.carousel-inner {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel-item {
  transition-duration: 375ms;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  align-items: center;
  backface-visibility: hidden;
  display: none;
  perspective: 1000px;
  position: relative;
  width: 100%;
}

@media (min-width: 576px) {
  .carousel-item {
    transition-duration: 487.5ms;
  }
}

@media (min-width: 992px) {
  .carousel-item {
    transition-duration: 250ms;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: flex;
}

.carousel-item-left.active,
.carousel-item-prev {
  transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-left.active,
  .carousel-item-prev {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-item-left.carousel-item-next,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-left.carousel-item-next,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.carousel-item-right.active {
  transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .carousel-item-right.active {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-fade .carousel-item {
  transition-duration: 375ms;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
}

@media (min-width: 576px) {
  .carousel-fade .carousel-item {
    transition-duration: 487.5ms;
  }
}

@media (min-width: 992px) {
  .carousel-fade .carousel-item {
    transition-duration: 250ms;
  }
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-left.active,
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item-prev.active {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-fade .carousel-item.active,
  .carousel-fade .carousel-item-left.active,
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item-prev.active {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-fade .carousel-item-left.active,
.carousel-fade .carousel-item-right.active {
  opacity: 0;
}

.carousel-control-next,
.carousel-control-prev {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 0.8125rem;
  height: 2.5rem;
  justify-content: center;
  line-height: 1;
  margin-top: -1.25rem;
  opacity: 0;
  position: absolute;
  top: 50%;
  user-select: none;
  width: 2.5rem;
}

.carousel-control-next:hover, .carousel-control-next:focus, .carousel-control-next:active,
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-prev:active {
  background-color: rgba(204, 204, 204, 0.25);
  color: white;
  text-decoration: none;
}

.carousel-control-next:active,
.carousel-control-prev:active {
  box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
}

.carousel-control-next:focus,
.carousel-control-prev:focus {
  opacity: 1;
  outline: 0;
}

.carousel-control-next {
  right: 1.25rem;
}

.carousel-control-prev {
  left: 1.25rem;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  font-size: 1.5rem;
  line-height: 1.5rem;
  vertical-align: -0.362637em;
  font-family: 'Material Icons';
  font-family: var(--font-family-material-icons);
  font-feature-settings: 'liga';
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}

.carousel-control-next-icon::before {
  content: "chevron_right";
}

.carousel-control-prev-icon::before {
  content: "chevron_left";
}

.carousel-caption {
  color: white;
  position: absolute;
  right: 25%;
  bottom: 1.5rem;
  left: 25%;
  text-align: center;
  z-index: 1;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  position: absolute;
  right: 5rem;
  bottom: 0.5rem;
  left: 5rem;
  z-index: 1;
}

.carousel-indicators li {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 0.5rem;
  cursor: pointer;
  flex: 0 0 auto;
  height: 0.5rem;
  margin-right: 1px;
  margin-left: 1px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 0.5rem;
}

.carousel-indicators .active {
  background-color: rgba(204, 204, 204, 0.25);
}

.close {
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1);
  appearance: none;
  background-color: transparent;
  background-image: none;
  border: 0;
  color: rgba(0, 0, 0, 0.38);
  float: right;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1;
  padding: 0;
}

@media (min-width: 576px) {
  .close {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .close {
    transition-duration: 280ms;
  }
}

.close:hover, .close:focus, .close:active {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.close:focus {
  outline: 0;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close {
  background-color: transparent;
  border: 0;
  padding: 0;
}

a.close.disabled {
  pointer-events: none;
}

code {
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #bd4147;
  font-size: 87.5%;
  padding: 0.2rem 0.4rem;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.87);
  color: white;
  font-size: 87.5%;
  padding: 0.2rem 0.4rem;
}

kbd kbd {
  font-size: 100%;
  font-weight: 600;
  padding: 0;
}

pre {
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 87.5%;
}

pre code {
  background-color: transparent;
  border-radius: 0;
  color: inherit;
  font-size: inherit;
  padding: 0;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.form-check {
  display: block;
  margin-bottom: 0.5rem;
  padding-left: 1.25rem;
  position: relative;
}

.form-check-input {
  margin-top: 0.333333rem;
  margin-left: -1.25rem;
  position: absolute;
}

.form-check-input[disabled] ~ .form-check-label {
  color: rgba(0, 0, 0, 0.38);
}

.form-check-label {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

.form-check-inline {
  align-items: center;
  display: inline-flex;
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.form-check-inline .form-check-input {
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
  position: static;
}

.form-group {
  margin-bottom: 1rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.form-inline {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
}

.form-inline .form-check {
  margin-bottom: 0;
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label, .form-inline legend {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    vertical-align: middle;
    width: auto;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .form-control-plaintext:focus {
    outline: 0;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-left: 0;
    width: auto;
  }
  .form-inline .form-check-input {
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 1.25rem;
    margin-left: 0;
    position: relative;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.col-form-label {
  color: inherit;
  font-size: 1rem;
  line-height: 1.5;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.col-form-label-lg {
  font-size: 2.125rem;
  line-height: 1.176471;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.col-form-label-sm {
  font-size: 0.8125rem;
  line-height: 1.538462;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.form-text, [class*='valid-feedback'] {
  color: rgba(0, 0, 0, 0.6);
  display: block;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.033333em;
  line-height: normal;
}

.form-text::before, [class*='valid-feedback']::before {
  content: '';
  display: inline-block;
  height: 1rem;
  vertical-align: 0;
  width: 0;
}

[class~='textfield-box'] .form-text, [class~='textfield-box'] [class*='valid-feedback'],
[class~='textfield-box'] ~ .form-text,
[class~='textfield-box'] ~ [class*='valid-feedback'] {
  padding: 0 1rem;
}

.form-control-plaintext {
  background-color: transparent;
  border: solid transparent;
  border-radius: 0;
  border-width: 0 0 1px;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 0 calc(0.375rem - 1px);
  width: 100%;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.img-fluid {
  height: auto;
  max-width: 100%;
}

.img-thumbnail {
  /*  padding: $thumbnail-padding;
  background-color: $thumbnail-bg;
  border: $thumbnail-border-width solid $thumbnail-border-color;
  @include border-radius($thumbnail-border-radius);
  @include box-shadow($thumbnail-box-shadow);
  */
  height: auto;
  max-width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.figure {
  display: inline-block;
}

.figure-img {
  line-height: 1;
  margin-bottom: 0.5rem;
}

.figure-caption {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.033333em;
  line-height: 1.25rem;
  color: rgba(0, 0, 0, 0.38);
}

.jumbotron {
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 3rem;
  padding: 3rem 2rem;
}

.jumbotron-fluid {
  border-radius: 0;
  padding-right: 0;
  padding-left: 0;
}

.media {
  align-items: flex-start;
  display: flex;
}

.media-body {
  flex: 1;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-link {
  color: #6200ee;
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
  pointer-events: none;
}

.nav-link:hover, .nav-link.active {
  color: #40009c;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex: 1;
}

.nav-pills .nav-link {
  border-radius: 4px;
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: background-color, color, opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.7;
}

@media (min-width: 576px) {
  .nav-pills .nav-link {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .nav-pills .nav-link {
    transition-duration: 280ms;
  }
}

.nav-pills .nav-link:hover, .nav-pills .nav-link:focus, .nav-pills .nav-link:active {
  background-color: rgba(0, 0, 0, 0.12);
}

.nav-pills .nav-link.active {
  color: #6200ee;
  opacity: 1;
}

.nav-pills .nav-link.disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}

.nav-pills .nav-link:active {
  opacity: 1;
}

.nav-pills .show > .nav-link {
  background-color: rgba(0, 0, 0, 0.12);
  opacity: 1;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.pagination {
  background-color: transparent;
  display: flex;
  list-style: none;
  margin: 0.5rem;
}

.page-link {
  border-radius: 1.125rem;
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  justify-content: center;
  line-height: 2.25;
  margin-left: 0.5em;
  min-width: 2.25rem;
  padding: 0 10px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

@media (min-width: 576px) {
  .page-link {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .page-link {
    transition-duration: 280ms;
  }
}

.page-link:hover, .page-link:focus, .page-link:active {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  z-index: 3;
}

.page-link:focus {
  background-color: rgba(0, 0, 0, 0.12);
  outline: 0;
}

.page-link:active {
  background-color: rgba(0, 0, 0, 0.24);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
}

.page-item.active .page-link {
  background-color: #6200ee;
  color: #ffffff;
  z-index: 3;
}

.page-item.disabled .page-link {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
  cursor: auto;
  pointer-events: none;
}

.pagination-lg .page-link {
  border-radius: 1.40625rem;
  font-size: 1.25rem;
  width: 2.8125rem;
}

.pagination-sm .page-link {
  font-size: 0.875rem;
  min-width: 0;
  width: 1.96875rem;
}

.popover {
  text-align: left;
  text-align: start;
  font-family: "Roboto", system-ui, -apple-system, "Segoe UI", "Helvetica Neue", "Arial", "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
  font-size: 0.875rem;
  margin: 1.5rem;
  max-width: 21.25rem;
  position: absolute;
  top: 0;
  left: 0;
  word-wrap: break-word;
  z-index: 240;
}

[dir='rtl'] .popover {
  text-align: right;
  text-align: start;
}

.popover-header {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  margin-bottom: 0;
  padding: 0.875rem 1.375rem 0;
}

.popover-header:empty {
  display: none;
}

.popover-header:last-child {
  padding-bottom: 0.875rem;
}

.popover-body {
  padding: 0.875rem 1.375rem;
}

.popover-body > :last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .popover {
    margin: 0.875rem;
  }
}

.embed-responsive {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}

.embed-responsive::before {
  content: '';
  display: block;
}

.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video,
.embed-responsive .embed-responsive-item {
  border: 0;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.fade {
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
  height: 0;
  overflow: hidden;
  position: relative;
}

@media (min-width: 576px) {
  .collapsing {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .collapsing {
    transition-duration: 280ms;
  }
}

tbody.collapse.show {
  display: table-row-group;
}

tr.collapse.show {
  display: table-row;
}

.btn, .card-link {
  border-radius: 4px;
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  align-items: center;
  background-color: transparent;
  background-image: none;
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 500;
  height: 2.25rem;
  justify-content: center;
  letter-spacing: 0.0892857143em;
  line-height: inherit;
  margin: 0;
  max-width: 100%;
  min-width: 4rem;
  outline: 0;
  padding: 0 1rem;
  position: relative;
  text-transform: uppercase;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

@media (min-width: 576px) {
  .btn, .card-link {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .btn, .card-link {
    transition-duration: 280ms;
  }
}

.btn:hover, .card-link:hover, .btn:focus, .card-link:focus {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  outline: 0;
  text-decoration: none;
}

.btn:hover, .card-link:hover, .btn.hover, .hover.card-link {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04));
  color: inherit;
}

.btn:focus, .card-link:focus, .btn.focus, .focus.card-link {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
}

.btn:active, .card-link:active, .btn.active, .active.card-link {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.btn.active, .active.card-link {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24));
  transition: background-image 0.2s;
}

.btn:disabled, .card-link:disabled, .btn.disabled, .disabled.card-link {
  background-color: rgba(0, 0, 0, 0.12);
  background-image: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.37);
  pointer-events: none;
}

.show > .btn.dropdown-toggle, .show > .dropdown-toggle.card-link {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32));
}

a.btn:not([class*='btn-flat']):not([class*='btn-outline']):enabled:hover, a.card-link:not([class*='btn-flat']):not([class*='btn-outline']):enabled:hover {
  color: #ffffff;
}

.btn-primary {
  background-color: #6200ee;
  color: white;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  color: white;
}

.btn-primary:hover {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08));
}

.btn-primary:focus, .btn-primary.focus {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24));
}

.btn-primary.active {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32));
  transition: background-image 15ms linear 0.2s;
}

.btn-secondary {
  background-color: #03dac6;
  color: white;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
  color: white;
}

.btn-secondary:hover {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08));
}

.btn-secondary:focus, .btn-secondary.focus {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24));
}

.btn-secondary.active {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32));
  transition: background-image 15ms linear 0.2s;
}

.btn-danger {
  background-color: #b00020;
  color: white;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active {
  color: white;
}

.btn-danger:hover {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08));
}

.btn-danger:focus, .btn-danger.focus {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24));
}

.btn-danger.active {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32));
  transition: background-image 15ms linear 0.2s;
}

.btn-info {
  background-color: #714cfe;
  color: white;
}

.btn-info:hover, .btn-info:focus, .btn-info:active {
  color: white;
}

.btn-info:hover {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08));
}

.btn-info:focus, .btn-info.focus {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24));
}

.btn-info.active {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32));
  transition: background-image 15ms linear 0.2s;
}

.btn-success {
  background-color: #61d800;
  color: white;
}

.btn-success:hover, .btn-success:focus, .btn-success:active {
  color: white;
}

.btn-success:hover {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08));
}

.btn-success:focus, .btn-success.focus {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24));
}

.btn-success.active {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32));
  transition: background-image 15ms linear 0.2s;
}

.btn-warning {
  background-color: #ff8d00;
  color: white;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active {
  color: white;
}

.btn-warning:hover {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08));
}

.btn-warning:focus, .btn-warning.focus {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24));
}

.btn-warning.active {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32));
  transition: background-image 15ms linear 0.2s;
}

.btn-dark {
  background-color: #424242;
  color: white;
}

.btn-dark:hover, .btn-dark:focus, .btn-dark:active {
  color: white;
}

.btn-dark:hover {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08));
}

.btn-dark:focus, .btn-dark.focus {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24));
}

.btn-dark.active {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.32), rgba(255, 255, 255, 0.32));
  transition: background-image 15ms linear 0.2s;
}

.btn-light {
  background-color: #ffffff;
}

[class*='bg-dark'] .btn:disabled, [class*='bg-dark'] .card-link:disabled, [class*='bg-dark'] .btn.disabled, [class*='bg-dark'] .disabled.card-link {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.3);
}

[class*='bg-outline']:hover,
[class*='bg-outline']:active {
  box-shadow: none;
  transition: background-color 100ms linear;
}

.btn-lg,
.btn-group-lg > .btn,
.btn-group-lg > .card-link,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .card-link,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .card-link {
  font-size: 0.9375rem;
  height: 2.5rem;
}

.btn-sm,
.btn-group-sm > .btn,
.btn-group-sm > .card-link,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .card-link,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .card-link {
  height: 1.75rem;
}

.btn-xs {
  height: 1.5rem;
}

.btn-block {
  margin: 0;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.25rem;
  margin-left: 0;
}

[type='button'].btn-block,
[type='reset'].btn-block,
[type='submit'].btn-block {
  width: 100%;
}

.btn-fluid {
  min-width: 0;
}

.btn-shaped {
  border-radius: 1.125rem;
}

.btn-shaped.btn-lg {
  border-radius: 1.25rem;
}

.btn-shaped.btn-sm {
  border-radius: 0.875rem;
}

.btn-shaped.btn-xs {
  border-radius: 0.75rem;
}

.btn .btn-icon-prepend, .card-link .btn-icon-prepend {
  display: inline-block;
  fill: currentColor;
  font-size: 1.125rem;
  margin-right: 0.5rem;
  margin-left: -0.25rem;
  vertical-align: top;
  width: 1.125rem;
}

.btn-icon-prepend [class*='btn-flat'],
.btn-link .btn-icon-prepend {
  margin-left: 0;
}

[class*='btn-flat'],
.btn-link,
.card-link,
.modal .btn,
.modal .card-link {
  padding: 0 0.5rem;
}

[class*='btn-outline'] {
  border: 1px solid currentColor;
  padding: 0 calc(1rem - 1px);
}

[class*='btn-outline']:disabled, [class*='btn-outline'].disabled {
  border-color: rgba(0, 0, 0, 0.37);
}

[class*='btn-flat'],
[class*='btn-outline'],
.btn-link,
.card-link,
.modal .btn,
.modal .card-link {
  background: transparent;
  box-shadow: none;
}

[class*='btn-flat']:hover, [class*='btn-flat']:focus, [class*='btn-flat']:active,
[class*='btn-outline']:hover,
[class*='btn-outline']:focus,
[class*='btn-outline']:active,
.btn-link:hover,
.btn-link:focus,
.btn-link:active,
.card-link:hover,
.card-link:focus,
.card-link:active,
.modal .btn:hover,
.modal .card-link:hover,
.modal .btn:focus,
.modal .card-link:focus,
.modal .btn:active,
.modal .card-link:active {
  box-shadow: inherit;
}

.btn-flat-primary, .btn-link, .card-link, .btn-flat-primary:hover, .btn-link:hover, .card-link:hover, .btn-flat-primary:focus, .btn-link:focus, .card-link:focus,
.btn-outline-primary,
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.modal .btn-primary,
.modal .btn-primary:hover,
.modal .btn-primary:focus {
  color: #6200ee;
}

.btn-flat-primary:hover, .btn-link:hover, .card-link:hover,
.btn-outline-primary:hover,
.modal .btn-primary:hover {
  background-image: linear-gradient(to bottom, rgba(98, 0, 238, 0.04), rgba(98, 0, 238, 0.04));
}

.btn-flat-primary:focus, .btn-link:focus, .card-link:focus,
.btn-outline-primary:focus,
.modal .btn-primary:focus {
  background-image: linear-gradient(to bottom, rgba(98, 0, 238, 0.12), rgba(98, 0, 238, 0.12));
}

.btn-flat-primary:disabled, .btn-link:disabled, .card-link:disabled, .btn-flat-primary.disabled, .disabled.btn-link, .disabled.card-link,
.btn-outline-primary:disabled,
.btn-outline-primary.disabled,
.modal .btn-primary:disabled,
.modal .btn-primary.disabled {
  color: rgba(0, 0, 0, 0.37);
}

.btn-flat-secondary, .btn-flat-secondary:hover, .btn-flat-secondary:focus,
.btn-outline-secondary,
.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.modal .btn-secondary,
.modal .btn-secondary:hover,
.modal .btn-secondary:focus {
  color: #03dac6;
}

.btn-flat-secondary:hover,
.btn-outline-secondary:hover,
.modal .btn-secondary:hover {
  background-image: linear-gradient(to bottom, rgba(3, 218, 198, 0.04), rgba(3, 218, 198, 0.04));
}

.btn-flat-secondary:focus,
.btn-outline-secondary:focus,
.modal .btn-secondary:focus {
  background-image: linear-gradient(to bottom, rgba(3, 218, 198, 0.12), rgba(3, 218, 198, 0.12));
}

.btn-flat-secondary:disabled, .btn-flat-secondary.disabled,
.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled,
.modal .btn-secondary:disabled,
.modal .btn-secondary.disabled {
  color: rgba(0, 0, 0, 0.37);
}

.btn-flat-danger, .btn-flat-danger:hover, .btn-flat-danger:focus,
.btn-outline-danger,
.btn-outline-danger:hover,
.btn-outline-danger:focus,
.modal .btn-danger,
.modal .btn-danger:hover,
.modal .btn-danger:focus {
  color: #b00020;
}

.btn-flat-danger:hover,
.btn-outline-danger:hover,
.modal .btn-danger:hover {
  background-image: linear-gradient(to bottom, rgba(176, 0, 32, 0.04), rgba(176, 0, 32, 0.04));
}

.btn-flat-danger:focus,
.btn-outline-danger:focus,
.modal .btn-danger:focus {
  background-image: linear-gradient(to bottom, rgba(176, 0, 32, 0.12), rgba(176, 0, 32, 0.12));
}

.btn-flat-danger:disabled, .btn-flat-danger.disabled,
.btn-outline-danger:disabled,
.btn-outline-danger.disabled,
.modal .btn-danger:disabled,
.modal .btn-danger.disabled {
  color: rgba(0, 0, 0, 0.37);
}

.btn-flat-info, .btn-flat-info:hover, .btn-flat-info:focus,
.btn-outline-info,
.btn-outline-info:hover,
.btn-outline-info:focus,
.modal .btn-info,
.modal .btn-info:hover,
.modal .btn-info:focus {
  color: #714cfe;
}

.btn-flat-info:hover,
.btn-outline-info:hover,
.modal .btn-info:hover {
  background-image: linear-gradient(to bottom, rgba(113, 76, 254, 0.04), rgba(113, 76, 254, 0.04));
}

.btn-flat-info:focus,
.btn-outline-info:focus,
.modal .btn-info:focus {
  background-image: linear-gradient(to bottom, rgba(113, 76, 254, 0.12), rgba(113, 76, 254, 0.12));
}

.btn-flat-info:disabled, .btn-flat-info.disabled,
.btn-outline-info:disabled,
.btn-outline-info.disabled,
.modal .btn-info:disabled,
.modal .btn-info.disabled {
  color: rgba(0, 0, 0, 0.37);
}

.btn-flat-success, .btn-flat-success:hover, .btn-flat-success:focus,
.btn-outline-success,
.btn-outline-success:hover,
.btn-outline-success:focus,
.modal .btn-success,
.modal .btn-success:hover,
.modal .btn-success:focus {
  color: #61d800;
}

.btn-flat-success:hover,
.btn-outline-success:hover,
.modal .btn-success:hover {
  background-image: linear-gradient(to bottom, rgba(97, 216, 0, 0.04), rgba(97, 216, 0, 0.04));
}

.btn-flat-success:focus,
.btn-outline-success:focus,
.modal .btn-success:focus {
  background-image: linear-gradient(to bottom, rgba(97, 216, 0, 0.12), rgba(97, 216, 0, 0.12));
}

.btn-flat-success:disabled, .btn-flat-success.disabled,
.btn-outline-success:disabled,
.btn-outline-success.disabled,
.modal .btn-success:disabled,
.modal .btn-success.disabled {
  color: rgba(0, 0, 0, 0.37);
}

.btn-flat-warning, .btn-flat-warning:hover, .btn-flat-warning:focus,
.btn-outline-warning,
.btn-outline-warning:hover,
.btn-outline-warning:focus,
.modal .btn-warning,
.modal .btn-warning:hover,
.modal .btn-warning:focus {
  color: #ff8d00;
}

.btn-flat-warning:hover,
.btn-outline-warning:hover,
.modal .btn-warning:hover {
  background-image: linear-gradient(to bottom, rgba(255, 141, 0, 0.04), rgba(255, 141, 0, 0.04));
}

.btn-flat-warning:focus,
.btn-outline-warning:focus,
.modal .btn-warning:focus {
  background-image: linear-gradient(to bottom, rgba(255, 141, 0, 0.12), rgba(255, 141, 0, 0.12));
}

.btn-flat-warning:disabled, .btn-flat-warning.disabled,
.btn-outline-warning:disabled,
.btn-outline-warning.disabled,
.modal .btn-warning:disabled,
.modal .btn-warning.disabled {
  color: rgba(0, 0, 0, 0.37);
}

.btn-flat-dark:disabled, .btn-flat-dark.disabled,
.btn-outline-dark:disabled,
.btn-outline-dark.disabled,
.modal .btn-dark:disabled,
.modal .btn-dark.disabled {
  color: rgba(0, 0, 0, 0.37);
}

.btn-flat-light, .btn-flat-light:hover, .btn-flat-light:focus,
.btn-outline-light,
.btn-outline-light:hover,
.btn-outline-light:focus,
.modal .btn-light,
.modal .btn-light:hover,
.modal .btn-light:focus {
  color: #f5f5f5;
}

.btn-flat-light:disabled, .btn-flat-light.disabled,
.btn-outline-light:disabled,
.btn-outline-light.disabled,
.modal .btn-light:disabled,
.modal .btn-light.disabled {
  color: rgba(0, 0, 0, 0.37);
}

/*
.btn-flat-light,
.btn-outline-light {
  @include hover-focus {
    background-image: linear-gradient(to bottom, $btn-overlay-inverse, $btn-overlay-inverse);
  }

  &.active,
  &:active {
    background-color: $btn-bg-active-inverse;
  }
}
*/
.btn-icon {
  border-radius: 50%;
  box-shadow: none;
  color: initial;
  fill: currentColor;
  font-size: 1.5rem;
  height: 3rem;
  min-width: 0;
  padding: 0;
  transition: background-color 15ms linear;
  width: 3rem;
}

.btn-icon.btn-sm {
  height: 2.25rem;
  width: 2.25rem;
}

.btn-icon.btn-xs {
  height: 1.75rem;
  width: 1.75rem;
}

.btn-icon:hover, .btn-icon:focus, .btn-icon:active {
  box-shadow: none;
  color: initial;
}

.btn-float {
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  height: 3.5rem;
  min-width: 0;
  padding: 0;
  width: 3.5rem;
}

.btn-float:hover {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.btn-float:active, .btn-float.active {
  box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);
}

.btn-float:disabled, .btn-float.disabled {
  box-shadow: none;
}

.btn-float svg {
  fill: currentColor;
}

.btn-float-extended {
  border-radius: 1.5rem;
  height: 3rem;
  line-height: 2.25rem;
  padding: 0 1.25rem;
  width: auto;
}

.btn-float-extended i,
.btn-float-extended svg,
.btn-float-extended [class*='material-icons'] {
  margin-right: 0.75rem;
  margin-left: -0.5rem;
}

.btn-float-extended i[class*='order-'],
.btn-float-extended svg[class*='order-'],
.btn-float-extended [class*='material-icons'][class*='order-'] {
  margin-right: -0.5rem;
  margin-left: 0.75rem;
}

.btn-float.btn-sm {
  height: 2.5rem;
  line-height: 2.5rem;
  width: 2.5rem;
}

.btn-float-dropdown .dropdown-menu {
  border-radius: 0;
  margin-top: 1rem;
  min-width: 3.5rem;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
}

.btn-float-dropdown .dropdown-menu::before {
  display: none;
}

.btn-float-dropdown .dropdown-menu .btn-float {
  display: block;
  margin-right: auto;
  margin-bottom: 1rem;
  margin-left: auto;
}

.fab-actions {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  z-index: 120;
}

@media print {
  .fab-actions {
    display: none;
  }
}

.fab-actions > .btn, .fab-actions > .card-link {
  margin-top: 1.25rem;
}

.btn-group,
.btn-group-vertical {
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  display: inline-flex;
  position: relative;
}

.btn-group > .btn-primary.disabled, .btn-group > .btn-primary:disabled,
.btn-group-vertical > .btn-primary.disabled,
.btn-group-vertical > .btn-primary:disabled {
  background-color: #bb86fc;
  color: white;
}

.btn-group > .btn-secondary.disabled, .btn-group > .btn-secondary:disabled,
.btn-group-vertical > .btn-secondary.disabled,
.btn-group-vertical > .btn-secondary:disabled {
  background-color: #66fff8;
  color: rgba(0, 0, 0, 0.87);
}

.btn-group > .btn-danger.disabled, .btn-group > .btn-danger:disabled,
.btn-group-vertical > .btn-danger.disabled,
.btn-group-vertical > .btn-danger:disabled {
  background-color: #c51162;
  color: white;
}

.btn-group > .btn-info.disabled, .btn-group > .btn-info:disabled,
.btn-group-vertical > .btn-info.disabled,
.btn-group-vertical > .btn-info:disabled {
  background-color: #d2c2fd;
  color: rgba(0, 0, 0, 0.87);
}

.btn-group > .btn-success.disabled, .btn-group > .btn-success:disabled,
.btn-group-vertical > .btn-success.disabled,
.btn-group-vertical > .btn-success:disabled {
  background-color: #c6f68d;
  color: rgba(0, 0, 0, 0.87);
}

.btn-group > .btn-warning.disabled, .btn-group > .btn-warning:disabled,
.btn-group-vertical > .btn-warning.disabled,
.btn-group-vertical > .btn-warning:disabled {
  background-color: #ffc77d;
  color: rgba(0, 0, 0, 0.87);
}

.btn-group > .btn-dark.disabled, .btn-group > .btn-dark:disabled,
.btn-group-vertical > .btn-dark.disabled,
.btn-group-vertical > .btn-dark:disabled {
  background-color: #757575;
  color: white;
}

.btn-group > .btn-light.disabled, .btn-group > .btn-light:disabled,
.btn-group-vertical > .btn-light.disabled,
.btn-group-vertical > .btn-light:disabled {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.btn-group > .btn, .btn-group > .card-link,
.btn-group-vertical > .btn,
.btn-group-vertical > .card-link {
  box-shadow: none;
  flex: 1 1 auto;
  min-width: 0;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  position: relative;
}

.btn-group > .btn:hover, .btn-group > .card-link:hover, .btn-group > .btn:focus, .btn-group > .card-link:focus, .btn-group > .btn:active, .btn-group > .card-link:active, .btn-group > .btn.active, .btn-group > .active.card-link,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .card-link:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .card-link:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .card-link:active,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .active.card-link {
  z-index: 1;
}

.btn-group > .btn:active, .btn-group > .card-link:active, .btn-group > .btn.active, .btn-group > .active.card-link,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .card-link:active,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .active.card-link {
  background-color: rgba(0, 0, 0, 0.12);
}

.btn-group > .btn:disabled, .btn-group > .card-link:disabled, .btn-group > .btn.disabled, .btn-group > .disabled.card-link,
.btn-group-vertical > .btn:disabled,
.btn-group-vertical > .card-link:disabled,
.btn-group-vertical > .btn.disabled,
.btn-group-vertical > .disabled.card-link {
  opacity: 0.7;
}

.btn-group > .btn[class*='btn-outline'], .btn-group > .card-link[class*='btn-outline'],
.btn-group-vertical > .btn[class*='btn-outline'],
.btn-group-vertical > .card-link[class*='btn-outline'] {
  border: 0;
}

.btn-group > .btn[class*='btn-outline'].active, .btn-group > .card-link[class*='btn-outline'].active, .btn-group > .btn[class*='btn-outline']:active, .btn-group > .card-link[class*='btn-outline']:active,
.btn-group-vertical > .btn[class*='btn-outline'].active,
.btn-group-vertical > .card-link[class*='btn-outline'].active,
.btn-group-vertical > .btn[class*='btn-outline']:active,
.btn-group-vertical > .card-link[class*='btn-outline']:active {
  background-color: rgba(0, 0, 0, 0.12);
}

.btn-group > .btn-group,
.btn-group > .btn-group-vertical,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group-vertical {
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
}

.btn-group.show > .dropdown-toggle {
  box-shadow: none;
}

.btn-group > .btn, .btn-group > .card-link,
.btn-group > .btn-group {
  margin-left: -1px;
}

.btn-group > .btn:first-child, .btn-group > .card-link:first-child,
.btn-group > .btn-group:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:first-child), .btn-group > .card-link:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .card-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .card-link:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .card-link,
.btn-group > .dropdown-toggle:not(:last-of-type) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}


.btn-group-vertical > .btn,
.btn-group-vertical > .card-link,
.btn-group-vertical > .btn-group {
  margin-top: -1px;
  margin-left: 0;
  width: 100%;
}

.btn-group-vertical > .btn:first-child, .btn-group-vertical > .card-link:first-child,
.btn-group-vertical > .btn-group:first-child {
  margin-top: 0;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .card-link:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .card-link {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .card-link:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn-group:not(:last-child) > .card-link,
.btn-group-vertical > .dropdown-toggle:not(:last-of-type) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/* .btn-group-fluid {
  background-color: transparent;
  box-shadow: none;
} */
[data-toggle='buttons'] > .btn, [data-toggle='buttons'] > .card-link,
[data-toggle='buttons'] > .btn-group > .btn,
[data-toggle='buttons'] > .btn-group > .card-link {
  margin-bottom: 0;
}

[data-toggle='buttons'] > .btn [type='checkbox'], [data-toggle='buttons'] > .card-link [type='checkbox'],
[data-toggle='buttons'] > .btn [type='radio'],
[data-toggle='buttons'] > .card-link [type='radio'],
[data-toggle='buttons'] > .btn-group > .btn [type='checkbox'],
[data-toggle='buttons'] > .btn-group > .card-link [type='checkbox'],
[data-toggle='buttons'] > .btn-group > .btn [type='radio'],
[data-toggle='buttons'] > .btn-group > .card-link [type='radio'] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

/* .btn-sm + .dropdown-toggle-split {
  padding-right: $btn-padding-x-sm * .75;
  padding-left: $btn-padding-x-sm * .75;
}

.btn-lg + .dropdown-toggle-split {
  padding-right: $btn-padding-x-lg * .75;
  padding-left: $btn-padding-x-lg * .75;
} */
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group-toggle > .btn, .btn-group-toggle > .card-link,
.btn-group-toggle > .btn-group > .btn,
.btn-group-toggle > .btn-group > .card-link {
  margin-bottom: 0;
}

.btn-group-toggle > .btn [type='radio'], .btn-group-toggle > .card-link [type='radio'],
.btn-group-toggle > .btn [type='checkbox'],
.btn-group-toggle > .card-link [type='checkbox'],
.btn-group-toggle > .btn-group > .btn [type='radio'],
.btn-group-toggle > .btn-group > .card-link [type='radio'],
.btn-group-toggle > .btn-group > .btn [type='checkbox'],
.btn-group-toggle > .btn-group > .card-link [type='checkbox'] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.card {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  word-wrap: break-word;
}

.card.border-primary {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #6200ee;
}

.card.border-primary[href]:hover, .card.border-primary[href]:focus, .card.border-primary[href]:active, .card.border-primary[tabindex]:hover, .card.border-primary[tabindex]:focus, .card.border-primary[tabindex]:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #6200ee;
}

.card.border-secondary {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #03dac6;
}

.card.border-secondary[href]:hover, .card.border-secondary[href]:focus, .card.border-secondary[href]:active, .card.border-secondary[tabindex]:hover, .card.border-secondary[tabindex]:focus, .card.border-secondary[tabindex]:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #03dac6;
}

.card.border-danger {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #b00020;
}

.card.border-danger[href]:hover, .card.border-danger[href]:focus, .card.border-danger[href]:active, .card.border-danger[tabindex]:hover, .card.border-danger[tabindex]:focus, .card.border-danger[tabindex]:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #b00020;
}

.card.border-info {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #714cfe;
}

.card.border-info[href]:hover, .card.border-info[href]:focus, .card.border-info[href]:active, .card.border-info[tabindex]:hover, .card.border-info[tabindex]:focus, .card.border-info[tabindex]:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #714cfe;
}

.card.border-success {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #61d800;
}

.card.border-success[href]:hover, .card.border-success[href]:focus, .card.border-success[href]:active, .card.border-success[tabindex]:hover, .card.border-success[tabindex]:focus, .card.border-success[tabindex]:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #61d800;
}

.card.border-warning {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #ff8d00;
}

.card.border-warning[href]:hover, .card.border-warning[href]:focus, .card.border-warning[href]:active, .card.border-warning[tabindex]:hover, .card.border-warning[tabindex]:focus, .card.border-warning[tabindex]:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #ff8d00;
}

.card.border-dark {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #424242;
}

.card.border-dark[href]:hover, .card.border-dark[href]:focus, .card.border-dark[href]:active, .card.border-dark[tabindex]:hover, .card.border-dark[tabindex]:focus, .card.border-dark[tabindex]:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #424242;
}

.card.border-light {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #f5f5f5;
}

.card.border-light[href]:hover, .card.border-light[href]:focus, .card.border-light[href]:active, .card.border-light[tabindex]:hover, .card.border-light[tabindex]:focus, .card.border-light[tabindex]:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2), inset 0 0 0 1px #f5f5f5;
}

.card[href]:hover, .card[href]:focus, .card[href]:active, .card[tabindex]:hover, .card[tabindex]:focus, .card[tabindex]:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.card[href]:focus, .card[tabindex]:focus {
  outline: 0;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.card-shaped {
  border-radius: 1.5rem 0.5rem;
}

[data-toggle='collapse']:hover {
  cursor: pointer;
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  box-shadow: none;
  margin-bottom: 0;
  overflow: hidden;
}

.accordion > .card + .card {
  border-top: 0;
}

.accordion > .card .show {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.accordion > .card > .card-header {
  border-bottom: 0;
  display: flex;
  padding: 15px 1.25rem;
}

.accordion > .card > .card-header:hover {
  background: rgba(0, 0, 0, 0.04);
  transition: background-color 150ms linear;
}

.accordion > .card > .card-header::after {
  content: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" opacity="0.54"%3e%3cpath d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/%3e%3c/svg%3e');
  height: 1.5rem;
  position: absolute;
  top: 1.25rem;
  right: 1.5rem;
  width: 1.5rem;
}

.accordion > .card > .card-header > * {
  line-height: 1.125rem;
}

.accordion .card:first-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-body:first-child,
.accordion .card:not(:first-of-type) .card-footer:first-child,
.accordion .card:not(:first-of-type) .card-header:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion .card:not(:last-of-type) .card-body:last-child,
.accordion .card:not(:last-of-type) .card-footer:last-child,
.accordion .card:not(:last-of-type) .card-header:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card-body {
  margin: 0 2.5rem;
  opacity: 0;
  padding: 1.5rem 0;
  transition: opacity 250ms linear -100ms;
}

.accordion .show .card-body {
  opacity: 1;
}

.accordion .collapse .card-body:first-child,
.accordion .collapse .card-footer:first-child,
.accordion .collapse .card-header:first-child,
.accordion .collapsing .card-body:first-child,
.accordion .collapsing .card-footer:first-child,
.accordion .collapsing .card-header:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-actions {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-height: 3.25rem;
  padding: 0.5rem;
}

.card-actions.flex-column {
  align-items: flex-start;
  padding-bottom: 0.25rem;
}

.card-actions.flex-column .btn, .card-actions.flex-column .card-link {
  margin-bottom: 0.25rem;
}

.card-body + .card-actions {
  background-color: #ffffff;
  margin-top: -0.5rem;
  z-index: 2;
}

.card-actions .btn, .card-actions .card-link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 0 1 auto;
  min-width: 0;
}

.card-actions .btn:not(.btn-icon), .card-actions .card-link:not(.btn-icon) {
  margin-right: 0.5rem;
}

.card-actions .btn-icon:not(:disabled) {
  color: rgba(0, 0, 0, 0.6);
}

.card-actions .dropdown-toggle::after {
  margin-right: 0;
}

.card-actions > * {
  z-index: 3;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-body:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card-body:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.card-body > :last-child {
  margin-bottom: 0;
}

.card-body > .btn, .card-body > .card-link {
  margin-right: 0.5rem;
}

.card-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1rem 1rem;
}

.card-footer:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top: 0;
}

.card-footer:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.card-footer > :last-child {
  margin-bottom: 0;
}

.card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 0;
  padding: 1rem 1rem;
}

.card-header:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card-header:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom: 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-header-pills {
  margin: -1rem -1rem;
  padding: 0.5rem 0rem 0.25rem 0.5rem;
}

.card-header-pills .nav-link {
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.card-header-tabs {
  margin: -1rem -1rem calc(-1rem - 1px);
}

.card-img {
  border-radius: 4px;
  min-height: 1px;
  width: 100%;
}

.card-img-bottom {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  min-height: 1px;
  width: 100%;
}

.card-img-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  min-height: 1px;
  width: 100%;
}

.card-img-overlay {
  border-radius: 3px;
  max-height: 100%;
  padding: 1rem 1rem;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 2;
    column-gap: 0.5rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .card-columns {
    column-count: 3;
  }
}

.card-columns .card {
  margin-bottom: 0.25rem;
}

.card-deck {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -0.25rem;
    margin-left: -0.25rem;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 0.25rem;
    margin-bottom: 0;
    margin-left: 0.25rem;
  }
}

.card-deck .card {
  margin-bottom: 0.5rem;
}

.card-group {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child:not(:last-child) .card-actions,
  .card-group > .card:first-child:not(:last-child) .card-body,
  .card-group > .card:first-child:not(:last-child) .card-footer,
  .card-group > .card:first-child:not(:last-child) .card-header,
  .card-group > .card:first-child:not(:last-child) .card-img,
  .card-group > .card:first-child:not(:last-child) .card-img-bottom,
  .card-group > .card:first-child:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child:not(:first-child) .card-actions,
  .card-group > .card:last-child:not(:first-child) .card-body,
  .card-group > .card:last-child:not(:first-child) .card-footer,
  .card-group > .card:last-child:not(:first-child) .card-header,
  .card-group > .card:last-child:not(:first-child) .card-img,
  .card-group > .card:last-child:not(:first-child) .card-img-bottom,
  .card-group > .card:last-child:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child) .card-actions,
  .card-group > .card:not(:first-child):not(:last-child) .card-body,
  .card-group > .card:not(:first-child):not(:last-child) .card-footer,
  .card-group > .card:not(:first-child):not(:last-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child) .card-img,
  .card-group > .card:not(:first-child):not(:last-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child) .card-img-top {
    border-radius: 0;
  }
  .card-group > .card + .card {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    margin-left: 0;
  }
}

.card-group > .card {
  box-shadow: none;
  margin-bottom: 0.5rem;
}

.card-body > .card-link {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.card-title {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  margin: 0;
}

.card-body .card-title {
  margin-bottom: 1rem;
}

.card-body .card-title + .card-subtitle {
  margin-top: -1rem;
}

.card-header + .card-body > .card-title {
  margin-top: 1rem;
}

.card-subtitle {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.007143em;
  line-height: 1.375rem;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 0;
}

.card-body .card-subtitle {
  padding-bottom: 1rem;
}

.card-text {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.017857em;
  line-height: 1.25rem;
  color: rgba(0, 0, 0, 0.6);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-primary-action {
  cursor: pointer;
  position: relative;
  transition: background-color 15ms linear, box-shadow 15ms linear;
}

.card-primary-action:hover {
  background: rgba(0, 0, 0, 0.04);
}

.card-primary-action:hover.card {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.chip {
  align-items: center;
  background-color: #e0e0e0;
  border: 0;
  border-radius: 1rem;
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  font-size: 0.875rem;
  height: 2rem;
  justify-content: center;
  letter-spacing: 0.017857em;
  line-height: 1.25rem;
  margin: 0.25rem;
  overflow: hidden;
  padding: 0 0.75rem;
  position: relative;
  transition: background-color 15ms linear;
  vertical-align: middle;
  white-space: nowrap;
}

.chip:empty {
  display: none;
}

.chip .close {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"%3e%3cpath fill="%23666" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/%3e%3c/svg%3e');
  height: 18px;
  margin-right: -0.25rem;
  margin-left: 0.5rem;
  order: 1;
  width: 18px;
}

.chip > [class*='material-icons'] {
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.25rem;
  line-height: 1;
}

.chip-primary,
.chip-primary:hover {
  background-color: #6200ee;
  color: white;
}

.chip-secondary,
.chip-secondary:hover {
  background-color: #03dac6;
  color: white;
}

.chip-danger,
.chip-danger:hover {
  background-color: #b00020;
  color: white;
}

.chip-info,
.chip-info:hover {
  background-color: #714cfe;
  color: white;
}

.chip-success,
.chip-success:hover {
  background-color: #61d800;
  color: white;
}

.chip-warning,
.chip-warning:hover {
  background-color: #ff8d00;
  color: white;
}

.chip-dark,
.chip-dark:hover {
  background-color: #424242;
  color: white;
}

.chip-light,
.chip-light:hover {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}

.chip-outline {
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.chip-outline .close {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"%3e%3cpath fill="%239e9e9e" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/%3e%3cpath d="M0 0h24v24H0z" fill="none"/%3e%3c/svg%3e');
}

.chip-action:hover, .chip-action:focus, .chip-action:active {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.chip-action:not(.disabled):hover {
  background-color: rgba(0, 0, 0, 0.24);
}

.chip-action:not(.disabled):active {
  background-color: rgba(0, 0, 0, 0.32);
}

.chip-action:not(.disabled):focus {
  outline: 0;
}

.chip-action:not(.disabled).chip-outline:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.chip-action:not(.disabled).chip-outline:active {
  background-color: rgba(0, 0, 0, 0.24);
}

[type='radio']:checked + .chip-choice {
  background-color: rgba(98, 0, 238, 0.24);
  color: #6200ee;
}

[type='radio']:checked + .chip-choice:hover, [type='radio']:checked + .chip-choice:active {
  background-color: rgba(98, 0, 238, 0.32);
}

[type='radio']:checked + .chip-choice.chip-outline {
  background-color: rgba(98, 0, 238, 0.12);
}

[type='radio']:checked + .chip-choice.chip-outline:hover, [type='radio']:checked + .chip-choice.chip-outline:active {
  background-color: rgba(98, 0, 238, 0.24);
}

[type='checkbox'] + .chip-filter:not(.chip-outline) {
  color: rgba(0, 0, 0, 0.54);
}

[type='checkbox']:checked + .chip-filter {
  background-color: rgba(0, 0, 0, 0.16);
  color: rgba(0, 0, 0, 0.87);
}

[type='checkbox']:checked + .chip-filter.chip-outline {
  background-color: rgba(0, 0, 0, 0.08);
}

[type='checkbox'] + .chip-filter::before {
  content: '';
  height: 1.125rem;
  opacity: 0;
  transform: translateX(-0.25rem);
  transition: width 180ms ease-in;
  width: 0;
}

[type='checkbox']:checked + .chip-filter::before {
  content: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"%3e%3cpath d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/%3e%3c/svg%3e');
  margin-right: 0.25rem;
  opacity: 1;
  transition: opacity 180ms 90ms, width 180ms ease-in;
  width: 1.125rem;
}

.chip-img, .chip > [class*='material-icons'], .chip-icon {
  border-radius: 0.625rem;
  flex-shrink: 0;
  height: 1.25rem;
  margin-right: 0.25rem;
  margin-left: -0.25rem;
  order: -1;
  width: 1.25rem;
}

.chip-icon {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  background-color: #03dac6;
  color: white;
  display: inline-flex;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
}

.chip-sm {
  height: 1.75rem;
}

.chip-xs {
  height: 1.5rem;
}

.chip.disabled,
input:disabled + .chip {
  opacity: 0.38;
}

html[data-theme='dark'] {
  filter: invert(1) hue-rotate(180deg);
}

html[data-theme='dark'] body,
html[data-theme='dark'] label,
html[data-theme='dark'] legend {
  font-weight: 500;
}

html[data-theme='dark'] img,
html[data-theme='dark'] video,
html[data-theme='dark'] .prevent-dark-theme {
  filter: invert(1) hue-rotate(180deg);
}

body {
  transition: color 300ms, background-color 300ms;
}

.data-table {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 1rem;
  max-width: 100%;
  overflow: auto;
  scrollbar-width: thin;
}

.data-table > .table td,
.data-table > .table th {
  white-space: nowrap;
}

.data-table > .table > thead th {
  border-top: 0;
}

.table {
  background-color: #ffffff;
  border: 0;
  font-size: 0.875rem;
  width: 100%;
}

.table td,
.table th {
  border-top: 1px solid #e1e1e1;
  font-weight: 400;
  padding-right: 1rem;
  padding-left: 1rem;
  vertical-align: middle;
}

.table tbody td,
.table tbody th {
  height: 3.25rem;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
}

.table tfoot {
  color: rgba(0, 0, 0, 0.54);
}

.table tfoot td,
.table tfoot th {
  font-size: 0.75rem;
  font-weight: 400;
  height: 3.5rem;
}

.table thead td,
.table thead th {
  font-weight: 500;
  height: 3.5rem;
  letter-spacing: 0.007143em;
  line-height: 1.375rem;
}

.card > .table:first-child,
.card > .table:first-child > :first-child,
.card > .table:first-child > :first-child > tr:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card > .table:first-child > :first-child > tr:first-child td:first-child,
.card > .table:first-child > :first-child > tr:first-child th:first-child {
  border-top-left-radius: 4px;
}

.card > .table:first-child > :first-child > tr:first-child td:last-child,
.card > .table:first-child > :first-child > tr:first-child th:last-child {
  border-top-right-radius: 4px;
}

.card > .table:last-child,
.card > .table:last-child > :last-child,
.card > .table:last-child > :last-child > tr:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.card > .table:last-child > :last-child > tr:last-child td:first-child,
.card > .table:last-child > :last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 4px;
}

.card > .table:last-child > :last-child > tr:last-child td:last-child,
.card > .table:last-child > :last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 4px;
}

.table .table {
  border-top: 1px solid #e1e1e1;
}

.table > :first-child > tr:first-child td,
.table > :first-child > tr:first-child th {
  border-top: 0;
}

.table-bordered {
  border: 1px solid #e1e1e1;
}

.card > .table-bordered {
  border: 0;
}

.table-borderless td,
.table-borderless th,
.table-borderless .table {
  border: 0;
}

.table-sm tbody td,
.table-sm tbody th {
  height: 2.25rem;
}

.table-sm tfoot td,
.table-sm tfoot th,
.table-sm thead td,
.table-sm thead th {
  height: 2.5rem;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f5f5f5;
}

.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #bb86fc;
  color: white;
}

.table-hover .table-primary:hover,
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #6200ee;
  color: white;
}

.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #66fff8;
  color: rgba(0, 0, 0, 0.87);
}

.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #03dac6;
  color: white;
}

.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #c51162;
  color: white;
}

.table-hover .table-danger:hover,
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #b00020;
  color: white;
}

.table-info,
.table-info > td,
.table-info > th {
  background-color: #d2c2fd;
  color: rgba(0, 0, 0, 0.87);
}

.table-hover .table-info:hover,
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #714cfe;
  color: white;
}

.table-success,
.table-success > td,
.table-success > th {
  background-color: #c6f68d;
  color: rgba(0, 0, 0, 0.87);
}

.table-hover .table-success:hover,
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #61d800;
  color: white;
}

.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #ffc77d;
  color: rgba(0, 0, 0, 0.87);
}

.table-hover .table-warning:hover,
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ff8d00;
  color: white;
}

.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #757575;
  color: white;
}

.table-hover .table-dark:hover,
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #424242;
  color: white;
}

.table-light,
.table-light > td,
.table-light > th {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.table-hover .table-light:hover,
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}

.table-hover tbody tr:not(.table-active):hover,
.data-table tbody tr:not(.table-active):hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(98, 0, 238, 0.04);
}

.table .thead-dark td,
.table .thead-dark th {
  background-color: #3c3c46;
  color: #ffffff;
}

.table .thead-light td,
.table .thead-light th {
  background-color: #f5f5f5;
}

.table-dark {
  background-color: #3c3c46;
  color: #ffffff;
}

.table-dark.table-bordered {
  border-color: #55555d;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: #303030;
}

.table-dark.table-hover tbody tr:hover {
  background-color: #565761;
}

.table-dark tbody,
.table-dark tfoot {
  color: inherit;
}

.table-dark thead {
  color: rgba(255, 255, 255, 0.6);
}

.table-dark td,
.table-dark th,
.table-dark .table {
  border-color: #55555d;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    overflow-x: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    overflow-x: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    overflow-x: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    overflow-x: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  overflow-x: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.modal-open {
  overflow: hidden;
}

.modal-open .fade {
  transition: opacity 150ms cubic-bezier(0, 0, 0.2, 1);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  display: none;
  height: 100%;
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 240;
}

.modal.fade .modal-dialog {
  transition: opacity, transform 75ms cubic-bezier(0.4, 0, 1, 1);
  opacity: 0;
  transform: scale(0.8);
}

.modal-dialog {
  margin: 1rem auto;
  pointer-events: none;
  position: relative;
  width: calc(100% - 1rem * 2);
  will-change: opacity, transform;
}

.modal.show .modal-dialog {
  transition: opacity, transform 150ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
  transform: scale(1);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 2rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 2rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: calc(1rem - 1px);
}

.modal-dialog-scrollable .modal-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - 2rem);
}

.modal-dialog-centered::before {
  content: '';
  display: block;
  height: calc(100vh - 2rem);
  height: min-content;
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-content {
  background-clip: padding-box;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.32);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 239;
}

.modal-backdrop.fade {
  transition: opacity 75ms cubic-bezier(0.4, 0, 1, 1);
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 1;
}

.modal-header {
  align-items: center;
  border-bottom: 1px solid transparent;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem calc(0.625rem - 1px);
}

.modal-header:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.modal-title {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  margin: 0;
}

.modal-body {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.03125em;
  line-height: 1.5rem;
  color: rgba(0, 0, 0, 0.6);
  flex: 1 1 auto;
  padding: 1.25rem 1.5rem;
  position: relative;
}

.modal-header + .modal-body {
  padding: 0 1.5rem 1.25rem;
}

.modal-dialog-scrollable .modal-header + .modal-body {
  padding: 0.5rem 1.5rem;
}

.modal-body > :last-child {
  margin-bottom: 0;
}

.modal-footer {
  align-items: flex-end;
  border-top: 1px solid transparent;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0.5rem 0.5rem;
}

.modal-footer:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.modal-footer:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.modal-footer > * {
  margin-left: 0.5rem;
}

.modal-footer-stacked {
  flex-direction: column;
}

.modal-footer-stacked .btn + .btn, .modal-footer-stacked .card-link + .btn, .modal-footer-stacked .btn + .card-link, .modal-footer-stacked .card-link + .card-link {
  margin-top: 0.75rem;
}

.modal-scrollbar-measure {
  height: 50px;
  overflow: scroll;
  position: absolute;
  top: -99999px;
  width: 50px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 35rem;
  }
  .modal-sm {
    max-width: 17.5rem;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 52.5rem;
  }
}

.modal-xl {
  margin: 0;
  max-width: 100vw;
  width: 100vw;
}

.modal-xl .modal-content {
  border-radius: 0;
  height: 100vh;
  overflow: scroll;
}

@media (max-width: 767.98px) {
  .modal-content {
    border-radius: 16px;
  }
  .modal-footer {
    align-items: stretch;
    flex-direction: column-reverse;
    padding: 0;
  }
  .modal-footer .btn, .modal-footer .card-link {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    display: block;
    height: 3rem;
    letter-spacing: 0.04em;
    text-transform: lowercase;
  }
  .modal-footer .btn:first-of-type, .modal-footer .card-link:first-of-type {
    border-radius: 0;
  }
  .modal-footer .btn::first-letter, .modal-footer .card-link::first-letter {
    text-transform: uppercase;
  }
  .modal-footer > * {
    margin-left: 0;
  }
  .modal-footer-stacked {
    flex-direction: column;
  }
  .modal-footer-stacked .btn, .modal-footer-stacked .card-link,
  .modal-footer-stacked .card-link {
    margin: 0 !important;
  }
}

.list-group {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin-bottom: 0;
  padding: 0.5rem 0;
}

.list-group-item {
  align-items: center;
  border: 0;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  flex-shrink: 0;
  font-size: 1rem;
  letter-spacing: 0.009375em;
  line-height: 1.5;
  min-height: 3rem;
  padding: 0.75rem 1rem;
  position: relative;
  transition: background-color 15ms linear;
}

.list-group-item:hover, .list-group-item:focus, .list-group-item:active {
  text-decoration: none;
}

.list-group-item.d-flex {
  flex-wrap: nowrap;
  min-height: 3rem;
  padding: 0 1rem;
}

.card .list-group-item {
  padding-right: 1rem;
  padding-left: 1rem;
}

.list-group-item .btn-icon {
  color: rgba(0, 0, 0, 0.6);
}

.list-group-item .list-group-item-icon {
  color: rgba(0, 0, 0, 0.54);
  display: inline-block;
  fill: currentColor;
  flex: 0 0 1.5rem;
  font-size: 1.5rem;
  height: 1.5rem;
  margin-right: 2rem;
  vertical-align: top;
}

.list-group-item.active .list-group-item-icon {
  color: #6200ee;
}

.list-group-item .list-group-item-graphic {
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.3);
  fill: rgba(0, 0, 0, 0.3);
  flex: 0 0 2.5rem;
  font-size: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  margin: 0.5rem 1rem 0.5rem 0;
}

.list-group-shaped .list-group-item {
  border-radius: 0 2rem 2rem 0;
}

.list-group-item .custom-control {
  display: flex;
  padding-left: 0;
  width: 100%;
}

.list-group-item .custom-control-label {
  flex-grow: 1;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: 3rem;
}

.list-group-item .custom-control-label::before {
  line-height: 3rem;
  margin-top: 0;
  right: 0;
  left: auto;
}

.list-group-item .custom-control-label::after {
  line-height: 3rem !important;
  padding: 0 0.6875rem;
  right: 0;
  left: auto;
}

.list-group-item.active {
  background-color: rgba(98, 0, 238, 0.12);
  color: #6200ee;
  z-index: 2;
}

.list-group-item.active:hover {
  background-color: rgba(98, 0, 238, 0.16);
}

.list-group-item.active:focus {
  background-color: rgba(98, 0, 238, 0.24);
}

.list-group-item.disabled, .list-group-item:disabled {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.38);
  pointer-events: none;
}

.list-group-item-two-line {
  height: 4.5rem;
}

.list-group-item-two-line .list-group-item-text {
  align-self: flex-start;
}

.list-group-item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}

.list-group-item-text :first-child {
  display: block;
  line-height: normal;
  margin-top: 0;
  margin-bottom: -1.25rem;
}

.list-group-item-text :first-child::before {
  content: '';
  display: inline-block;
  height: 2rem;
  vertical-align: 0;
  width: 0;
}

.list-group-item-text :first-child::after {
  content: '';
  display: inline-block;
  height: 20px;
  vertical-align: -20px;
  width: 0;
}

.list-group-item-text :last-child {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.017857em;
  line-height: 1.25rem;
  color: rgba(0, 0, 0, 0.54);
  display: block;
  line-height: normal;
  margin-top: 0;
}

.list-group-item-text :last-child::before {
  content: '';
  display: inline-block;
  height: 20px;
  vertical-align: 0;
  width: 0;
}

.list-group-item-meta {
  color: rgba(0, 0, 0, 0.38);
  margin-right: 0;
  margin-left: auto;
}

.list-group-item-meta:not(i):not([class*='material-icon']) {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.033333em;
  line-height: 1.25rem;
}

.list-group-divider {
  border: none;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: 0;
  margin: 0;
}

.list-group-item-action {
  text-align: inherit;
  width: 100%;
}

.list-group-item-action:hover:not(.active), .list-group-item-action:focus:not(.active) {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  z-index: 1;
}

.list-group-item-action:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.list-group-item-action:focus {
  background-color: rgba(0, 0, 0, 0.12);
  outline: 0;
}

.list-group-item-action:disabled, .list-group-item-action.disabled {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.38);
}

[class*='list-group-horizontal'] {
  padding: 0;
}

[class*='list-group-horizontal'] .list-group-item {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 0;
}

[class*='list-group-horizontal'] .list-group-item:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

[class*='list-group-horizontal'] .list-group-item:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: 0;
  margin-right: 0;
}

.list-group-horizontal {
  flex-direction: row;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
}

.list-group-item-primary {
  background-color: #bb86fc;
  color: white;
}

.list-group-item-primary.active {
  background-color: #6200ee;
  color: white;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:active {
  background-color: #6200ee;
  color: white;
}

.list-group-item-secondary {
  background-color: #66fff8;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-secondary.active {
  background-color: #03dac6;
  color: white;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:active {
  background-color: #03dac6;
  color: white;
}

.list-group-item-danger {
  background-color: #c51162;
  color: white;
}

.list-group-item-danger.active {
  background-color: #b00020;
  color: white;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:active {
  background-color: #b00020;
  color: white;
}

.list-group-item-info {
  background-color: #d2c2fd;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-info.active {
  background-color: #714cfe;
  color: white;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:active {
  background-color: #714cfe;
  color: white;
}

.list-group-item-success {
  background-color: #c6f68d;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-success.active {
  background-color: #61d800;
  color: white;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:active {
  background-color: #61d800;
  color: white;
}

.list-group-item-warning {
  background-color: #ffc77d;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-warning.active {
  background-color: #ff8d00;
  color: white;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:active {
  background-color: #ff8d00;
  color: white;
}

.list-group-item-dark {
  background-color: #757575;
  color: white;
}

.list-group-item-dark.active {
  background-color: #424242;
  color: white;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:active {
  background-color: #424242;
  color: white;
}

.list-group-item-light {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-light.active {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:active {
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
}

.expansion-panel {
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: background-color, color, margin 300ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0;
}

@media (min-width: 576px) {
  .expansion-panel {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .expansion-panel {
    transition-duration: 280ms;
  }
}

.expansion-panel.show {
  border-radius: 4px;
}

.expansion-panel.show:not(:first-child) {
  margin-top: 1rem;
}

.card .expansion-panel.show:not(:first-child) {
  margin-top: 0;
}

.expansion-panel.show:not(:last-child) {
  margin-bottom: 1rem;
}

.card .expansion-panel.show:not(:last-child) {
  margin-bottom: 0;
}

.expansion-panel.show + .expansion-panel {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.expansion-panel.show + .expansion-panel.show {
  margin-top: 0;
}

.card .expansion-panel.show + .expansion-panel {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.expansion-panel.show-predecessor {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.card .expansion-panel.show-predecessor {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card .expansion-panel {
  padding-right: 0;
  padding-left: 0;
}

.expansion-panel-body {
  padding: 1rem 1rem;
}

.card .expansion-panel-body {
  padding-right: 1rem;
  padding-left: 1rem;
}

.expansion-panel-footer {
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0.5rem 0.75rem 0rem;
}

.card .expansion-panel-footer {
  padding-right: 1rem;
  padding-left: 0.5rem;
}

.expansion-panel-footer .btn, .expansion-panel-footer .card-link {
  margin-bottom: 0.25rem;
  margin-left: 0.5rem;
  min-width: 4rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.card .expansion-panel-footer .btn, .card .expansion-panel-footer .card-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.expansion-panel-icon {
  flex-shrink: 0;
  margin-left: 1rem;
}

[data-toggle='collapse'].collapsed .expansion-panel-icon .collapsed-hide {
  display: none;
}

[data-toggle='collapse']:not(.collapsed) .expansion-panel-icon .collapsed-show {
  display: none;
}

.expansion-panel-toggler {
  align-items: center;
  color: inherit;
  display: flex;
  justify-content: space-between;
  min-height: 3rem;
  padding: 0.75rem 1rem;
  text-align: inherit;
  width: 100%;
}

.expansion-panel-toggler:hover, .expansion-panel-toggler:focus, .expansion-panel-toggler:active {
  background-color: rgba(0, 0, 0, 0.24);
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.expansion-panel-toggler.disabled, .expansion-panel-toggler:disabled {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.38);
}

.expansion-panel-toggler:focus {
  outline: 0;
}

.card .expansion-panel-toggler {
  padding-right: 1rem;
  padding-left: 1rem;
}

.list-group-flush {
  border: 0;
  border-radius: 0;
  padding: 0;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-menu {
  background-color: transparent;
  color: inherit;
  display: none;
  float: left;
  font-size: 1rem;
  list-style: none;
  min-width: 7rem;
  padding: 0.5rem 0;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 80;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu.show::before,
.dropdown-menu.show > * {
  animation-duration: 0.12s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  /* @include media-breakpoint-up(sm) {
        animation-duration: $transition-duration-tablet;
      }

      @include media-breakpoint-up(lg) {
        animation-duration: $transition-duration-desktop;
      }
      */
}

.dropdown-menu.show::before {
  animation-name: dropdown-menu-show;
}

.dropdown-menu.show > * {
  animation-name: dropdown-item-show;
}

.dropdown-menu.show > :nth-child(1) {
  animation-name: dropdown-item-show-1;
}

.dropdown-menu.show > :nth-child(2) {
  animation-name: dropdown-item-show-2;
}

.dropdown-menu.show > :nth-child(3) {
  animation-name: dropdown-item-show-3;
}

.dropdown-menu::before {
  border-radius: 4px;
  background-clip: padding-box;
  background-color: #ffffff;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform-origin: top left;
  z-index: -1;
}

.nav:not(.flex-column):not(.flex-column-reverse) .dropdown-menu {
  min-width: 100%;
}

.menu {
  margin-top: -3.125rem;
}

.menu::before {
  transform-origin: 0 2rem;
}

.show > a {
  outline: 0;
}

.dropdown-menu-right:not([x-placement]) {
  right: 0;
  left: auto;
}

.dropdown-menu-right:not([x-placement]).menu::before {
  transform-origin: 100% 2rem;
}

.dropdown-menu-right:not([x-placement])::before {
  transform-origin: 100% 0;
}

.dropleft .dropdown-menu:not([x-placement]) {
  top: 0;
  right: 100%;
  left: auto;
}

.dropleft .dropdown-menu:not([x-placement]).menu {
  margin-top: 0;
}

.dropleft .dropdown-menu:not([x-placement])::before {
  transform-origin: 100% 0;
}

.dropright .dropdown-menu:not([x-placement]) {
  top: 0;
  right: auto;
  left: 100%;
}

.dropright .dropdown-menu:not([x-placement]).menu {
  margin-top: 0;
}

.dropright .dropdown-menu:not([x-placement])::before {
  transform-origin: 0 0;
}

.dropup .dropdown-menu:not([x-placement]) {
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-menu:not([x-placement]).menu {
  margin-top: 0;
  margin-bottom: -3.125rem;
}

.dropup .dropdown-menu:not([x-placement]).menu::before {
  transform-origin: 0 calc(100% - 2rem);
}

.dropup .dropdown-menu:not([x-placement])::before {
  transform-origin: 0 100%;
}

.dropup .dropdown-menu:not([x-placement]) > :nth-child(1),
.dropup .dropdown-menu:not([x-placement]) > :nth-child(2),
.dropup .dropdown-menu:not([x-placement]) > :nth-child(3) {
  animation-name: dropdown-item-show;
}

.dropup .dropdown-menu:not([x-placement]) > :nth-last-child(1) {
  animation-name: dropdown-item-show-1;
}

.dropup .dropdown-menu:not([x-placement]) > :nth-last-child(2) {
  animation-name: dropdown-item-show-2;
}

.dropup .dropdown-menu:not([x-placement]) > :nth-last-child(3) {
  animation-name: dropdown-item-show-3;
}

.dropup .dropdown-menu-right:not([x-placement]).menu::before {
  transform-origin: 100% calc(100% - 2rem);
}

.dropup .dropdown-menu-right:not([x-placement])::before {
  transform-origin: 100% 100%;
}

.dropdown-menu[x-placement='bottom-end']::before,
.dropdown-menu[x-placement='left-start']::before {
  transform-origin: 100% 0;
}

.dropdown-menu[x-placement='top-end'].show > :nth-child(1),
.dropdown-menu[x-placement='top-end'].show > :nth-child(2),
.dropdown-menu[x-placement='top-end'].show > :nth-child(3),
.dropdown-menu[x-placement='top-start'].show > :nth-child(1),
.dropdown-menu[x-placement='top-start'].show > :nth-child(2),
.dropdown-menu[x-placement='top-start'].show > :nth-child(3) {
  animation-name: dropdown-item-show;
}

.dropdown-menu[x-placement='top-end'].show > :nth-last-child(1),
.dropdown-menu[x-placement='top-start'].show > :nth-last-child(1) {
  animation-name: dropdown-item-show-1;
}

.dropdown-menu[x-placement='top-end'].show > :nth-last-child(2),
.dropdown-menu[x-placement='top-start'].show > :nth-last-child(2) {
  animation-name: dropdown-item-show-2;
}

.dropdown-menu[x-placement='top-end'].show > :nth-last-child(3),
.dropdown-menu[x-placement='top-start'].show > :nth-last-child(3) {
  animation-name: dropdown-item-show-3;
}

.dropdown-menu[x-placement='top-end']::before {
  transform-origin: 100% 100%;
}

.dropdown-menu[x-placement='top-start']::before {
  transform-origin: 0 100%;
}

.menu[x-placement='bottom-end']::before {
  transform-origin: 100% 2rem;
}

.menu[x-placement='left-start'],
.menu[x-placement='right-start'] {
  margin-top: 0;
}

.menu[x-placement='left-start']::before {
  transform-origin: 100% 0;
}

.menu[x-placement='right-start']::before {
  transform-origin: 0 0;
}

.menu[x-placement='top-end'],
.menu[x-placement='top-start'] {
  margin-top: 0;
  margin-bottom: -3.125rem;
}

.menu[x-placement='top-end']::before {
  transform-origin: 100% calc(100% - 2rem);
}

.menu[x-placement='top-start']::before {
  transform-origin: 0 calc(100% - 2rem);
}

@keyframes dropdown-item-show {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dropdown-item-show-1 {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dropdown-item-show-2 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dropdown-item-show-3 {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* @keyframes dropdown-menu-show {
  0% {
    transform: scale(0, 0);
  }

  20% {
    transform: scale((1 / 3), 0);
  }

  40% {
    transform: scale((2 / 3), 0.25);
  }

  60% {
    transform: scale(1, 0.5);
  }

  80% {
    transform: scale(1, 0.75);
  }

  100% {
    transform: scale(1, 1);
  }
} */
@keyframes dropdown-menu-show {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes menu-animation {
  0% {
    margin-top: -3.5rem;
  }
  100% {
    margin-top: 0;
  }
}

@media (min-width: 576px) {
  .dropdown-menu-sm,
  .menu-cascading {
    min-width: 17.5rem;
  }
}

.menu-cascading {
  margin-top: -2.75rem;
}

.menu-cascading[x-placement='top-end'], .menu-cascading[x-placement='top-start'] {
  margin-top: 0;
  margin-bottom: -2.75rem;
}

.menu-cascading[x-placement='top-end']::before {
  transform-origin: 100% calc(100% - 2rem);
}

.menu-cascading[x-placement='top-start']::before {
  transform-origin: 0 calc(100% - 2rem);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropdown-menu[x-placement^='top'], .dropdown-menu[x-placement^='right'], .dropdown-menu[x-placement^='bottom'], .dropdown-menu[x-placement^='left'] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  background-color: rgba(0, 0, 0, 0.12);
  height: 1px;
  margin: 0.5rem 0;
  overflow: hidden;
  border: 0;
}

.dropdown-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: inherit;
  font-weight: 500;
  letter-spacing: 0.009375em;
  line-height: inherit;
  margin: 0;
  padding: 0.75rem 1rem;
  transform-origin: 0 0;
  /* .dropdown-menu-sm &,
  .menu-cascading & {
    line-height: $menu-line-height-cascading;
    padding: $menu-link-padding-y-cascading $menu-link-padding-x-cascading;
  } */
}

.dropdown-item {
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: background-color, color 300ms cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: none;
  border: 0;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  letter-spacing: 0.009375em;
  line-height: 3rem;
  padding: 0 1rem;
  position: relative;
  text-align: inherit;
  transform-origin: 0 0;
  width: 100%;
}

@media (min-width: 576px) {
  .dropdown-item {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .dropdown-item {
    transition-duration: 280ms;
  }
}

.dropdown-item:hover, .dropdown-item:focus, .dropdown-item:active {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.dropdown-item:focus, .dropdown-item.active {
  background-color: rgba(0, 0, 0, 0.12);
  outline: 0;
}

.dropdown-item:active {
  background-color: rgba(0, 0, 0, 0.24);
}

.dropdown-item:disabled, .dropdown-item.disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
  pointer-events: none;
}

.dropdown-item > i,
.dropdown-item > [class*='material-icons'] {
  margin-right: 1.25rem;
  margin-left: 0.5rem;
}

.dropdown-item > .dropdown-icon-append {
  margin-right: 0.5rem;
  margin-left: auto;
  order: 12;
}

.dropdown-menu-sm .dropdown-item,
.menu-cascading .dropdown-item {
  line-height: 2rem;
}

.dropdown-item-text {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-weight: inherit;
  padding: 0.75rem 1rem;
  transform-origin: 0 0;
  /* .dropdown-menu-sm &,
  .menu-cascading & {
    padding: $menu-link-padding-y-cascading $menu-link-padding-x-cascading;
  } */
}

.dropdown-toggle::after {
  font-size: 1.5rem;
  line-height: 1.5rem;
  vertical-align: -0.362637em;
  font-family: 'Material Icons';
  font-family: var(--font-family-material-icons);
  font-feature-settings: 'liga';
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  content: "arrow_drop_down";
  display: inline-block;
  line-height: 1rem;
  margin-right: -0.2em;
  margin-left: 0.2em;
  vertical-align: top;
}

.dropright .dropdown-toggle::after {
  content: "arrow_right";
}

.dropup .dropdown-toggle::after {
  content: "arrow_drop_up";
}

.dropdown-toggle:empty::after {
  margin-left: -0.2em;
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  font-size: 1.5rem;
  line-height: 1.5rem;
  vertical-align: -0.362637em;
  font-family: 'Material Icons';
  font-family: var(--font-family-material-icons);
  font-feature-settings: 'liga';
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  content: "arrow_left";
  display: inline-block;
  margin-right: 0.2em;
  margin-left: -0.2em;
}

.dropdown-toggle-split {
  padding-right: 0.2em;
  padding-left: 0.2em;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-right: 0;
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
  margin-left: 0;
}

.navdrawer {
  display: none;
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 160;
}

.navdrawer-backdrop {
  transition-duration: 375ms;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(0, 0, 0, 0.32);
  opacity: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 159;
}

@media (min-width: 576px) {
  .navdrawer-backdrop {
    transition-duration: 487.5ms;
  }
}

@media (min-width: 992px) {
  .navdrawer-backdrop {
    transition-duration: 250ms;
  }
}

.navdrawer-backdrop.show {
  opacity: 1;
}

.navdrawer-content {
  transition-duration: 195ms;
  transition-property: box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  background-color: #ffffff;
  max-width: calc(100% - 3.5rem);
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translate3d(-100%, 0, 0);
  width: 16rem;
}

@media (min-width: 576px) {
  .navdrawer-content {
    transition-duration: 253.5ms;
  }
}

@media (min-width: 992px) {
  .navdrawer-content {
    transition-duration: 75ms;
  }
}

.navdrawer-right .navdrawer-content {
  right: 0;
  left: auto;
  transform: translate3d(100%, 0, 0);
}

.navdrawer.show .navdrawer-content {
  transition-duration: 225ms;
  transition-property: box-shadow, transform;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  transform: translate3d(0, 0, 0);
}

@media (min-width: 576px) {
  .navdrawer.show .navdrawer-content {
    transition-duration: 292.5ms;
  }
}

@media (min-width: 992px) {
  .navdrawer.show .navdrawer-content {
    transition-duration: 150ms;
  }
}

.navdrawer-body {
  margin-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.navdrawer-divider {
  background-color: rgba(0, 0, 0, 0.12);
  height: 1px;
  margin: 0.5rem 0;
  overflow: hidden;
}

.navdrawer-header {
  background-color: #f5f5f5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: block;
  margin-bottom: 0.5rem;
  padding: 0.625rem 1rem;
}

.navdrawer-subheader {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.38);
  display: block;
  font-weight: 500;
  height: 3rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0;
  padding: 1.0625rem 1rem;
}

.navdrawer-divider + .navdrawer-subheader {
  margin-top: -0.5rem;
}

.navdrawer-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 0;
}

.navdrawer-header + .navdrawer-nav,
.navdrawer-subheader + .navdrawer-nav {
  margin-top: 0;
}

.navdrawer-nav .nav-link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  font-weight: 500;
  height: 2.5rem;
  letter-spacing: 0.007143em;
  line-height: 1.375rem;
  margin: 0.5rem;
  padding: 0 0.5rem;
  transition: background-color 15ms;
}

.navdrawer-nav .nav-link:hover, .navdrawer-nav .nav-link:focus, .navdrawer-nav .nav-link:active {
  background-color: #f5f5f5;
}

.navdrawer-nav .nav-link.active, .navdrawer-nav .nav-link:active {
  background-color: rgba(98, 0, 238, 0.12);
  color: #6200ee;
}

.navdrawer-nav .nav-link.disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
}

.navdrawer-nav .nav-link:focus {
  outline: 0;
}

.navdrawer-nav .active > .nav-link {
  color: #6200ee;
}

.navdrawer-nav-icon {
  color: rgba(0, 0, 0, 0.54);
  width: 3.5rem;
}

.nav-link:active .navdrawer-nav-icon,
.nav-link.active .navdrawer-nav-icon {
  color: #6200ee;
}

.active > .nav-link .navdrawer-nav-icon {
  color: #6200ee;
}

@media (min-width: 576px) {
  .navdrawer-backdrop-permanent-sm {
    display: none;
    transition: none;
  }
  .navdrawer-backdrop-persistent-sm {
    display: none;
    transition: none;
  }
  .navdrawer-backdrop-temporary-sm {
    display: none;
    transition: none;
  }
  .navdrawer-permanent-sm {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    display: block !important;
    right: auto;
    width: 16rem;
    z-index: auto;
  }
  .navdrawer-permanent-sm.navdrawer-permanent-clipped, .navdrawer-permanent-sm.navdrawer-permanent-float {
    top: 3.5rem;
  }
  .navdrawer-permanent-sm.navdrawer-permanent-float {
    border-right: 0;
  }
  .navdrawer-permanent-sm.navdrawer-permanent-float.navdrawer-right {
    border-left: 0;
  }
  .navdrawer-permanent-sm.navdrawer-permanent-float .navdrawer-content {
    background-color: transparent;
  }
  .navdrawer-permanent-sm.navdrawer-right {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    right: 0;
    left: auto;
  }
  .navdrawer-permanent-sm.show .navdrawer-content {
    box-shadow: none;
  }
  .navdrawer-permanent-sm .navdrawer-content {
    max-width: none;
    position: absolute;
    transform: translate3d(0, 0, 0);
    width: 100%;
  }
  .navdrawer-persistent-sm {
    right: auto;
    width: 16rem;
    z-index: auto;
  }
  .navdrawer-persistent-sm.navdrawer-persistent-clipped {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: -1px;
    top: 3.5rem;
    z-index: 39;
  }
  .navdrawer-persistent-sm.navdrawer-right {
    right: 0;
    left: auto;
  }
  .navdrawer-persistent-sm.navdrawer-right .navdrawer-content {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
  .navdrawer-persistent-sm.show .navdrawer-content {
    box-shadow: none;
  }
  .navdrawer-persistent-sm .navdrawer-content {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    max-width: none;
    position: absolute;
    width: 100%;
  }
  .navdrawer-temporary-sm {
    overflow: visible;
    right: auto;
    width: 16rem;
  }
  .navdrawer-temporary-sm.navdrawer-right {
    right: 0;
    left: auto;
  }
  .navdrawer-temporary-sm .navdrawer-content {
    max-width: none;
    position: absolute;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .navdrawer-backdrop-permanent-md {
    display: none;
    transition: none;
  }
  .navdrawer-backdrop-persistent-md {
    display: none;
    transition: none;
  }
  .navdrawer-backdrop-temporary-md {
    display: none;
    transition: none;
  }
  .navdrawer-permanent-md {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    display: block !important;
    right: auto;
    width: 16rem;
    z-index: auto;
  }
  .navdrawer-permanent-md.navdrawer-permanent-clipped, .navdrawer-permanent-md.navdrawer-permanent-float {
    top: 3.5rem;
  }
  .navdrawer-permanent-md.navdrawer-permanent-float {
    border-right: 0;
  }
  .navdrawer-permanent-md.navdrawer-permanent-float.navdrawer-right {
    border-left: 0;
  }
  .navdrawer-permanent-md.navdrawer-permanent-float .navdrawer-content {
    background-color: transparent;
  }
  .navdrawer-permanent-md.navdrawer-right {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    right: 0;
    left: auto;
  }
  .navdrawer-permanent-md.show .navdrawer-content {
    box-shadow: none;
  }
  .navdrawer-permanent-md .navdrawer-content {
    max-width: none;
    position: absolute;
    transform: translate3d(0, 0, 0);
    width: 100%;
  }
  .navdrawer-persistent-md {
    right: auto;
    width: 16rem;
    z-index: auto;
  }
  .navdrawer-persistent-md.navdrawer-persistent-clipped {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: -1px;
    top: 3.5rem;
    z-index: 39;
  }
  .navdrawer-persistent-md.navdrawer-right {
    right: 0;
    left: auto;
  }
  .navdrawer-persistent-md.navdrawer-right .navdrawer-content {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
  .navdrawer-persistent-md.show .navdrawer-content {
    box-shadow: none;
  }
  .navdrawer-persistent-md .navdrawer-content {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    max-width: none;
    position: absolute;
    width: 100%;
  }
  .navdrawer-temporary-md {
    overflow: visible;
    right: auto;
    width: 16rem;
  }
  .navdrawer-temporary-md.navdrawer-right {
    right: 0;
    left: auto;
  }
  .navdrawer-temporary-md .navdrawer-content {
    max-width: none;
    position: absolute;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .navdrawer-backdrop-permanent-lg {
    display: none;
    transition: none;
  }
  .navdrawer-backdrop-persistent-lg {
    display: none;
    transition: none;
  }
  .navdrawer-backdrop-temporary-lg {
    display: none;
    transition: none;
  }
  .navdrawer-permanent-lg {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    display: block !important;
    right: auto;
    width: 16rem;
    z-index: auto;
  }
  .navdrawer-permanent-lg.navdrawer-permanent-clipped, .navdrawer-permanent-lg.navdrawer-permanent-float {
    top: 3.5rem;
  }
  .navdrawer-permanent-lg.navdrawer-permanent-float {
    border-right: 0;
  }
  .navdrawer-permanent-lg.navdrawer-permanent-float.navdrawer-right {
    border-left: 0;
  }
  .navdrawer-permanent-lg.navdrawer-permanent-float .navdrawer-content {
    background-color: transparent;
  }
  .navdrawer-permanent-lg.navdrawer-right {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    right: 0;
    left: auto;
  }
  .navdrawer-permanent-lg.show .navdrawer-content {
    box-shadow: none;
  }
  .navdrawer-permanent-lg .navdrawer-content {
    max-width: none;
    position: absolute;
    transform: translate3d(0, 0, 0);
    width: 100%;
  }
  .navdrawer-persistent-lg {
    right: auto;
    width: 16rem;
    z-index: auto;
  }
  .navdrawer-persistent-lg.navdrawer-persistent-clipped {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: -1px;
    top: 3.5rem;
    z-index: 39;
  }
  .navdrawer-persistent-lg.navdrawer-right {
    right: 0;
    left: auto;
  }
  .navdrawer-persistent-lg.navdrawer-right .navdrawer-content {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
  .navdrawer-persistent-lg.show .navdrawer-content {
    box-shadow: none;
  }
  .navdrawer-persistent-lg .navdrawer-content {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    max-width: none;
    position: absolute;
    width: 100%;
  }
  .navdrawer-temporary-lg {
    overflow: visible;
    right: auto;
    width: 16rem;
  }
  .navdrawer-temporary-lg.navdrawer-right {
    right: 0;
    left: auto;
  }
  .navdrawer-temporary-lg .navdrawer-content {
    max-width: none;
    position: absolute;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .navdrawer-backdrop-permanent-xl {
    display: none;
    transition: none;
  }
  .navdrawer-backdrop-persistent-xl {
    display: none;
    transition: none;
  }
  .navdrawer-backdrop-temporary-xl {
    display: none;
    transition: none;
  }
  .navdrawer-permanent-xl {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    display: block !important;
    right: auto;
    width: 16rem;
    z-index: auto;
  }
  .navdrawer-permanent-xl.navdrawer-permanent-clipped, .navdrawer-permanent-xl.navdrawer-permanent-float {
    top: 3.5rem;
  }
  .navdrawer-permanent-xl.navdrawer-permanent-float {
    border-right: 0;
  }
  .navdrawer-permanent-xl.navdrawer-permanent-float.navdrawer-right {
    border-left: 0;
  }
  .navdrawer-permanent-xl.navdrawer-permanent-float .navdrawer-content {
    background-color: transparent;
  }
  .navdrawer-permanent-xl.navdrawer-right {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    right: 0;
    left: auto;
  }
  .navdrawer-permanent-xl.show .navdrawer-content {
    box-shadow: none;
  }
  .navdrawer-permanent-xl .navdrawer-content {
    max-width: none;
    position: absolute;
    transform: translate3d(0, 0, 0);
    width: 100%;
  }
  .navdrawer-persistent-xl {
    right: auto;
    width: 16rem;
    z-index: auto;
  }
  .navdrawer-persistent-xl.navdrawer-persistent-clipped {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: -1px;
    top: 3.5rem;
    z-index: 39;
  }
  .navdrawer-persistent-xl.navdrawer-right {
    right: 0;
    left: auto;
  }
  .navdrawer-persistent-xl.navdrawer-right .navdrawer-content {
    border-right: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
  }
  .navdrawer-persistent-xl.show .navdrawer-content {
    box-shadow: none;
  }
  .navdrawer-persistent-xl .navdrawer-content {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    max-width: none;
    position: absolute;
    width: 100%;
  }
  .navdrawer-temporary-xl {
    overflow: visible;
    right: auto;
    width: 16rem;
  }
  .navdrawer-temporary-xl.navdrawer-right {
    right: 0;
    left: auto;
  }
  .navdrawer-temporary-xl .navdrawer-content {
    max-width: none;
    position: absolute;
    width: 100%;
  }
}

.navdrawer-backdrop-permanent {
  display: none;
  transition: none;
}

.navdrawer-backdrop-persistent {
  display: none;
  transition: none;
}

.navdrawer-backdrop-temporary {
  display: none;
  transition: none;
}

.navdrawer-permanent {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  display: block !important;
  right: auto;
  width: 16rem;
  z-index: auto;
}

.navdrawer-permanent.navdrawer-permanent-clipped, .navdrawer-permanent.navdrawer-permanent-float {
  top: 3.5rem;
}

.navdrawer-permanent.navdrawer-permanent-float {
  border-right: 0;
}

.navdrawer-permanent.navdrawer-permanent-float.navdrawer-right {
  border-left: 0;
}

.navdrawer-permanent.navdrawer-permanent-float .navdrawer-content {
  background-color: transparent;
}

.navdrawer-permanent.navdrawer-right {
  border-right: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  right: 0;
  left: auto;
}

.navdrawer-permanent.show .navdrawer-content {
  box-shadow: none;
}

.navdrawer-permanent .navdrawer-content {
  max-width: none;
  position: absolute;
  transform: translate3d(0, 0, 0);
  width: 100%;
}

.navdrawer-persistent {
  right: auto;
  width: 16rem;
  z-index: auto;
}

.navdrawer-persistent.navdrawer-persistent-clipped {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: -1px;
  top: 3.5rem;
  z-index: 39;
}

.navdrawer-persistent.navdrawer-right {
  right: 0;
  left: auto;
}

.navdrawer-persistent.navdrawer-right .navdrawer-content {
  border-right: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.navdrawer-persistent.show .navdrawer-content {
  box-shadow: none;
}

.navdrawer-persistent .navdrawer-content {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  max-width: none;
  position: absolute;
  width: 100%;
}

.navdrawer-temporary {
  overflow: visible;
  right: auto;
  width: 16rem;
}

.navdrawer-temporary.navdrawer-right {
  right: 0;
  left: auto;
}

.navdrawer-temporary .navdrawer-content {
  max-width: none;
  position: absolute;
  width: 100%;
}

.picker {
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 100%, 0);
  transition-delay: 675ms;
  transition-duration: 0;
  transition-property: transform;
  z-index: 240;
}

@media (min-width: 576px) {
  .picker {
    transition-delay: 877.5ms;
  }
}

@media (min-width: 992px) {
  .picker {
    transition-delay: 530ms;
  }
}

.picker.picker-opened {
  overflow-x: hidden;
  overflow-y: auto;
  transform: translate3d(0, 0, 0);
  transition: none;
}

.picker-frame {
  margin: 1rem 1rem;
}

.picker-holder {
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  align-items: center;
  background-color: rgba(0, 0, 0, 0.32);
  display: flex;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  pointer-events: none;
  transition-delay: 375ms;
}

@media (min-width: 576px) {
  .picker-holder {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .picker-holder {
    transition-duration: 280ms;
  }
}

@media (min-width: 576px) {
  .picker-holder {
    transition-delay: 487.5ms;
  }
}

@media (min-width: 992px) {
  .picker-holder {
    transition-delay: 250ms;
  }
}

.picker-opened .picker-holder {
  opacity: 1;
  transition-delay: 0s;
}

.picker-wrap {
  border-radius: 4px;
  transition-duration: 375ms;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.03125em;
  line-height: 1.5rem;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  max-width: 18.5rem;
  opacity: 0;
  outline: 0;
  pointer-events: auto;
  position: relative;
  transform: scale(0.87);
}

@media (min-width: 576px) {
  .picker-wrap {
    transition-duration: 487.5ms;
  }
}

@media (min-width: 992px) {
  .picker-wrap {
    transition-duration: 250ms;
  }
}

@media (orientation: landscape) {
  .picker-wrap {
    display: flex;
    max-width: none;
  }
}

.picker-opened .picker-wrap {
  opacity: 1;
  transform: scale(1);
}

.picker-footer {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0.5rem;
}

@media (orientation: landscape) {
  .picker-footer {
    border-radius: 0 0 4px 0;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}

.picker-footer button {
  flex: 0 1 auto;
  margin-left: 0.5rem;
  min-width: 0;
}

.picker-footer button:first-child {
  margin-left: 0;
}

.picker-header {
  height: 2.5rem;
  line-height: 2.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  padding-right: 3rem;
  padding-left: 3rem;
  position: relative;
  text-align: center;
}

@media (orientation: landscape) {
  .picker-header {
    margin-top: 0.5rem;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }
}

.picker-month,
.picker-year {
  display: inline;
  margin-left: 0.5rem;
}

.picker-month:first-child,
.picker-year:first-child {
  margin-left: 0;
}

.picker-nav-next,
.picker-nav-prev {
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.54);
  height: 2.5rem;
  margin-top: -1.25rem;
  position: absolute;
  top: 50%;
  width: 2.5rem;
}

.picker-nav-next, .picker-nav-next[class*='material-icons'],
.picker-nav-prev,
.picker-nav-prev[class*='material-icons'] {
  line-height: 2.5rem;
}

.picker-nav-next:not(.picker-nav-disabled):hover,
.picker-nav-prev:not(.picker-nav-disabled):hover {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04));
  cursor: pointer;
}

.picker-nav-next:not(.picker-nav-disabled):active,
.picker-nav-prev:not(.picker-nav-disabled):active {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
}

.picker-nav-next {
  right: 0;
}

.picker-nav-next::before {
  content: "keyboard_arrow_right";
}

.picker-nav-prev {
  right: 2.5rem;
}

.picker-nav-prev::before {
  content: "keyboard_arrow_left";
}

.picker-nav-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.picker-date-display {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #6200ee;
  color: white;
  padding: 1rem 1.5rem;
}

@media (orientation: landscape) {
  .picker-date-display {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    min-width: 9.75rem;
    white-space: nowrap;
  }
}

.picker-date-display-bottom {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 2.125rem;
  font-weight: 400;
  letter-spacing: 0.007353em;
  line-height: 2.5rem;
  font-weight: 300;
}

.picker-month-display {
  margin-right: 0.5rem;
}

.picker-weekday-display {
  margin-right: 0.5rem;
}

@media (orientation: landscape) {
  .picker-weekday-display {
    display: block;
    margin-right: 0;
  }
}

.picker-weekday-display::after {
  content: ',';
}

.picker-box {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #ffffff;
  overflow: hidden;
}

@media (orientation: landscape) {
  .picker-box {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
}

[class*='pick'].form-control[readonly] {
  border-bottom-style: solid;
  color: inherit;
  cursor: pointer;
}

[class*='pick'][readonly].form-control:hover, [class*='pick'].picker-input-active {
  border-color: rgba(0, 0, 0, 0.87);
}

[class*='pick'].form-control[disabled] {
  border-style: dotted;
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
}

.picker-day {
  border-radius: 50%;
  cursor: default;
  height: 2.5rem;
  line-height: 2.5rem;
  margin: auto;
  vertical-align: middle;
  width: 2.5rem;
}

@media (orientation: landscape) {
  .picker-day {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
}

.picker-day.picker-day-selected {
  background-color: #6200ee;
  color: white;
}

.picker-day:not(.picker-day-disabled):hover {
  cursor: pointer;
}

.picker-day-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.picker-day-outfocus {
  display: none;
}

.picker-day-today {
  border: 1px solid #a4a4a4;
}

.picker-table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0.5rem;
  table-layout: fixed;
}

.picker-table td,
.picker-table th {
  border: 0;
  padding: 0;
  text-align: center;
  vertical-align: middle;
}

.picker-table th {
  font-weight: inherit;
}

.picker-weekday {
  color: rgba(0, 0, 0, 0.38);
  height: 2.5rem;
  vertical-align: middle;
  width: 2.5rem;
}

.picker-select-month,
.picker-select-year {
  border-radius: 4px;
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  appearance: none;
  background-color: rgba(0, 0, 0, 0.12);
  background-image: url('data:image/svg+xml,%3csvg fill="%23000000" fill-opacity="0.54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3e%3cpath d="M7 10l5 5 5-5z"/%3e%3c/svg%3e');
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  border: 0;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  font-size: inherit;
  height: 1.25rem;
  line-height: 1.25rem;
  opacity: 0.7;
  padding: 0 1.25rem 0 0.5rem;
}

@media (min-width: 576px) {
  .picker-select-month,
  .picker-select-year {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .picker-select-month,
  .picker-select-year {
    transition-duration: 280ms;
  }
}

.picker-select-month:hover, .picker-select-month:focus,
.picker-select-year:hover,
.picker-select-year:focus {
  opacity: 1;
}

.picker-select-month:focus,
.picker-select-year:focus {
  outline: 0;
}

.picker-select-month {
  margin-left: 0.5rem;
}

.progress {
  display: flex;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  z-index: 1;
  /* @include font-size($progress-font-size);
  background-color: $progress-bg; */
}

.progress-bar {
  border-bottom: 0.25rem solid #6200ee;
  transition: width 250ms cubic-bezier(0.4, 0, 0.6, 1);
  will-change: width;
}

.progress-bar.bg-primary {
  background-color: transparent !important;
  border-bottom-color: #6200ee;
}

.progress-bar.bg-primary::after {
  background-color: #bb86fc;
}

.progress-bar.bg-secondary {
  background-color: transparent !important;
  border-bottom-color: #03dac6;
}

.progress-bar.bg-secondary::after {
  background-color: #66fff8;
}

.progress-bar.bg-danger {
  background-color: transparent !important;
  border-bottom-color: #b00020;
}

.progress-bar.bg-danger::after {
  background-color: #c51162;
}

.progress-bar.bg-info {
  background-color: transparent !important;
  border-bottom-color: #714cfe;
}

.progress-bar.bg-info::after {
  background-color: #d2c2fd;
}

.progress-bar.bg-success {
  background-color: transparent !important;
  border-bottom-color: #61d800;
}

.progress-bar.bg-success::after {
  background-color: #c6f68d;
}

.progress-bar.bg-warning {
  background-color: transparent !important;
  border-bottom-color: #ff8d00;
}

.progress-bar.bg-warning::after {
  background-color: #ffc77d;
}

.progress-bar.bg-dark {
  background-color: transparent !important;
  border-bottom-color: #424242;
}

.progress-bar.bg-dark::after {
  background-color: #757575;
}

.progress-bar.bg-light {
  background-color: transparent !important;
  border-bottom-color: #f5f5f5;
}

.progress-bar.bg-light::after {
  background-color: #fafafa;
}

.progress-bar::after {
  background-color: #e6e6e6;
  content: '';
  height: 0.25rem;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.progress-bar-animated::before {
  animation-direction: reverse;
  animation-duration: 300ms;
  animation-iteration-count: infinite;
  animation-name: progress-bar-animation;
  animation-timing-function: linear;
}

@media (min-width: 576px) {
  .progress-bar-animated::before {
    animation-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .progress-bar-animated::before {
    animation-duration: 280ms;
  }
}

.progress-bar-animated,
.progress-bar-striped {
  box-sizing: content-box;
  position: relative;
}

.progress-bar-animated.bg-primary::before,
.progress-bar-striped.bg-primary::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #bb86fc, #bb86fc 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #bb86fc, #bb86fc 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #bb86fc, #bb86fc 0.125rem, transparent 0.125rem, transparent 100%);
}

.progress-bar-animated.bg-secondary::before,
.progress-bar-striped.bg-secondary::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #66fff8, #66fff8 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #66fff8, #66fff8 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #66fff8, #66fff8 0.125rem, transparent 0.125rem, transparent 100%);
}

.progress-bar-animated.bg-danger::before,
.progress-bar-striped.bg-danger::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #c51162, #c51162 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #c51162, #c51162 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #c51162, #c51162 0.125rem, transparent 0.125rem, transparent 100%);
}

.progress-bar-animated.bg-info::before,
.progress-bar-striped.bg-info::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #d2c2fd, #d2c2fd 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #d2c2fd, #d2c2fd 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #d2c2fd, #d2c2fd 0.125rem, transparent 0.125rem, transparent 100%);
}

.progress-bar-animated.bg-success::before,
.progress-bar-striped.bg-success::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #c6f68d, #c6f68d 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #c6f68d, #c6f68d 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #c6f68d, #c6f68d 0.125rem, transparent 0.125rem, transparent 100%);
}

.progress-bar-animated.bg-warning::before,
.progress-bar-striped.bg-warning::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #ffc77d, #ffc77d 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #ffc77d, #ffc77d 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #ffc77d, #ffc77d 0.125rem, transparent 0.125rem, transparent 100%);
}

.progress-bar-animated.bg-dark::before,
.progress-bar-striped.bg-dark::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #757575, #757575 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #757575, #757575 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #757575, #757575 0.125rem, transparent 0.125rem, transparent 100%);
}

.progress-bar-animated.bg-light::before,
.progress-bar-striped.bg-light::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #fafafa, #fafafa 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #fafafa, #fafafa 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #fafafa, #fafafa 0.125rem, transparent 0.125rem, transparent 100%);
}

.progress-bar-animated::after,
.progress-bar-striped::after {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  right: -1.5rem;
  bottom: -0.25rem;
}

.progress-bar-animated::before,
.progress-bar-striped::before {
  background-image: repeating-radial-gradient(0.125rem 0.125rem, #e6e6e6, #e6e6e6 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -webkit-repeating-radial-gradient(0.125rem 0.125rem, #e6e6e6, #e6e6e6 0.125rem, transparent 0.125rem, transparent 100%);
  background-image: -moz-repeating-radial-gradient(0.125rem 0.125rem, #e6e6e6, #e6e6e6 0.125rem, transparent 0.125rem, transparent 100%);
  background-position: 0 0;
  background-repeat: repeat-x;
  background-size: 0.75rem 0.75rem;
  content: '';
  display: block;
  height: 0.25rem;
  position: absolute;
  right: -100vw;
  bottom: -0.25rem;
  left: 0;
  z-index: -1;
}

@keyframes progress-bar-animation {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0.75rem 0;
  }
}

.progress-bar-indeterminate {
  border-bottom-color: #e6e6e6;
  position: relative;
  width: 100%;
}

.progress-bar-indeterminate.bg-primary {
  border-bottom-color: #bb86fc;
}

.progress-bar-indeterminate.bg-primary::after, .progress-bar-indeterminate.bg-primary::before {
  background-color: #6200ee;
}

.progress-bar-indeterminate.bg-primary::before {
  background-image: none;
}

.progress-bar-indeterminate.bg-secondary {
  border-bottom-color: #66fff8;
}

.progress-bar-indeterminate.bg-secondary::after, .progress-bar-indeterminate.bg-secondary::before {
  background-color: #03dac6;
}

.progress-bar-indeterminate.bg-secondary::before {
  background-image: none;
}

.progress-bar-indeterminate.bg-danger {
  border-bottom-color: #c51162;
}

.progress-bar-indeterminate.bg-danger::after, .progress-bar-indeterminate.bg-danger::before {
  background-color: #b00020;
}

.progress-bar-indeterminate.bg-danger::before {
  background-image: none;
}

.progress-bar-indeterminate.bg-info {
  border-bottom-color: #d2c2fd;
}

.progress-bar-indeterminate.bg-info::after, .progress-bar-indeterminate.bg-info::before {
  background-color: #714cfe;
}

.progress-bar-indeterminate.bg-info::before {
  background-image: none;
}

.progress-bar-indeterminate.bg-success {
  border-bottom-color: #c6f68d;
}

.progress-bar-indeterminate.bg-success::after, .progress-bar-indeterminate.bg-success::before {
  background-color: #61d800;
}

.progress-bar-indeterminate.bg-success::before {
  background-image: none;
}

.progress-bar-indeterminate.bg-warning {
  border-bottom-color: #ffc77d;
}

.progress-bar-indeterminate.bg-warning::after, .progress-bar-indeterminate.bg-warning::before {
  background-color: #ff8d00;
}

.progress-bar-indeterminate.bg-warning::before {
  background-image: none;
}

.progress-bar-indeterminate.bg-dark {
  border-bottom-color: #757575;
}

.progress-bar-indeterminate.bg-dark::after, .progress-bar-indeterminate.bg-dark::before {
  background-color: #424242;
}

.progress-bar-indeterminate.bg-dark::before {
  background-image: none;
}

.progress-bar-indeterminate.bg-light {
  border-bottom-color: #fafafa;
}

.progress-bar-indeterminate.bg-light::after, .progress-bar-indeterminate.bg-light::before {
  background-color: #f5f5f5;
}

.progress-bar-indeterminate.bg-light::before {
  background-image: none;
}

.progress-bar-indeterminate::after, .progress-bar-indeterminate::before {
  border-radius: 0.25rem;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background-color: #6200ee;
  width: 0%;
}

.progress-bar-indeterminate::after {
  animation-name: progress-bar-indeterminate-after;
  bottom: -0.25rem;
  z-index: 0;
}

.progress-bar-indeterminate::before {
  animation-name: progress-bar-indeterminate-before;
  content: '';
  height: 0.25rem;
  position: absolute;
  bottom: -0.25rem;
  z-index: 0;
}

@keyframes progress-bar-indeterminate-after {
  0% {
    left: 0%;
    width: 0%;
  }
  50% {
    left: 25%;
    width: 75%;
  }
  75% {
    left: 100%;
    width: 0%;
  }
}

@keyframes progress-bar-indeterminate-before {
  0%,
  62.5% {
    left: 0%;
    width: 0%;
  }
  71.875% {
    left: 0%;
    width: 25%;
  }
  81.25% {
    left: 25%;
    width: 50%;
  }
  100% {
    left: 100%;
    width: 25%;
  }
}

.spinner-border {
  animation: spinner-border 1.333s linear infinite;
  border: 0.25rem solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
  height: 2.5rem;
  vertical-align: text-bottom;
  width: 2.5rem;
}

.spinner-border-sm {
  border-width: 0.15rem;
  height: 1rem;
  width: 1rem;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
}

.spinner-grow {
  animation: spinner-grow 0.75s linear infinite;
  background-color: currentColor;
  border-radius: 50%;
  display: inline-block;
  height: 2.5rem;
  opacity: 0;
  vertical-align: text-bottom;
  width: 2.5rem;
}

.spinner-grow-sm {
  height: 1rem;
  width: 1rem;
}

.progress-circular {
  height: 2.5rem;
  position: relative;
  width: 2.5rem;
}

.progress-circular-gap {
  border-top: 0.125rem solid #6200ee;
  position: absolute;
  top: 0;
  right: 1.1875rem;
  bottom: 0;
  left: 1.1875rem;
}

.progress-circular-inner {
  animation: progress-circular-inner-rotate 5.332s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  height: 2.5rem;
  position: relative;
  width: 2.5rem;
}

.progress-circular-left,
.progress-circular-right {
  height: 2.5rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1.25rem;
}

.progress-circular-left {
  left: 0;
}

.progress-circular-right {
  right: 0;
}

.progress-circular-spinner {
  border: 0.25rem solid #6200ee;
  border-bottom-color: transparent;
  border-radius: 50%;
  height: 2.5rem;
  position: absolute;
  top: 0;
  width: 2.5rem;
}

.progress-circular-left .progress-circular-spinner {
  animation: progress-circular-spinner-left 1.333s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  border-right-color: transparent;
  left: 0;
}

.progress-circular-right .progress-circular-spinner {
  animation: progress-circular-spinner-right 1.333s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  border-left-color: transparent;
  right: 0;
}

.progress-circular-wrapper {
  animation: spinner-border 2.666s linear infinite;
}

@keyframes progress-circular-inner-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

@keyframes progress-circular-spinner-left {
  0%,
  100% {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
}

@keyframes progress-circular-spinner-right {
  0%,
  100% {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.progress-circular-primary .progress-circular-gap,
.progress-circular-primary .progress-circular-spinner {
  border-top-color: #6200ee;
}

.progress-circular-primary .progress-circular-left .progress-circular-spinner {
  border-left-color: #6200ee;
}

.progress-circular-primary .progress-circular-right .progress-circular-spinner {
  border-right-color: #6200ee;
}

.progress-circular-secondary .progress-circular-gap,
.progress-circular-secondary .progress-circular-spinner {
  border-top-color: #03dac6;
}

.progress-circular-secondary .progress-circular-left .progress-circular-spinner {
  border-left-color: #03dac6;
}

.progress-circular-secondary .progress-circular-right .progress-circular-spinner {
  border-right-color: #03dac6;
}

.progress-circular-danger .progress-circular-gap,
.progress-circular-danger .progress-circular-spinner {
  border-top-color: #b00020;
}

.progress-circular-danger .progress-circular-left .progress-circular-spinner {
  border-left-color: #b00020;
}

.progress-circular-danger .progress-circular-right .progress-circular-spinner {
  border-right-color: #b00020;
}

.progress-circular-info .progress-circular-gap,
.progress-circular-info .progress-circular-spinner {
  border-top-color: #714cfe;
}

.progress-circular-info .progress-circular-left .progress-circular-spinner {
  border-left-color: #714cfe;
}

.progress-circular-info .progress-circular-right .progress-circular-spinner {
  border-right-color: #714cfe;
}

.progress-circular-success .progress-circular-gap,
.progress-circular-success .progress-circular-spinner {
  border-top-color: #61d800;
}

.progress-circular-success .progress-circular-left .progress-circular-spinner {
  border-left-color: #61d800;
}

.progress-circular-success .progress-circular-right .progress-circular-spinner {
  border-right-color: #61d800;
}

.progress-circular-warning .progress-circular-gap,
.progress-circular-warning .progress-circular-spinner {
  border-top-color: #ff8d00;
}

.progress-circular-warning .progress-circular-left .progress-circular-spinner {
  border-left-color: #ff8d00;
}

.progress-circular-warning .progress-circular-right .progress-circular-spinner {
  border-right-color: #ff8d00;
}

.progress-circular-dark .progress-circular-gap,
.progress-circular-dark .progress-circular-spinner {
  border-top-color: #424242;
}

.progress-circular-dark .progress-circular-left .progress-circular-spinner {
  border-left-color: #424242;
}

.progress-circular-dark .progress-circular-right .progress-circular-spinner {
  border-right-color: #424242;
}

.progress-circular-light .progress-circular-gap,
.progress-circular-light .progress-circular-spinner {
  border-top-color: #f5f5f5;
}

.progress-circular-light .progress-circular-left .progress-circular-spinner {
  border-left-color: #f5f5f5;
}

.progress-circular-light .progress-circular-right .progress-circular-spinner {
  border-right-color: #f5f5f5;
}

.custom-control {
  display: block;
  min-height: 1.5rem;
  padding-left: 2.25rem;
  position: relative;
  z-index: 1;
}

.custom-control + .custom-control {
  margin-top: 0.75rem;
}

.custom-control-inline {
  align-items: center;
  display: inline-flex;
  margin-right: 1.5rem;
}

.custom-control-inline + .custom-control-inline {
  margin-top: 0;
}

.custom-control-label {
  color: inherit;
  display: inline-block;
  font-size: 0.875rem;
  letter-spacing: 0.017857em;
  line-height: 1.5rem;
  margin-bottom: 0;
  user-select: none;
  vertical-align: middle;
}

.custom-control-label::before {
  transition: background-color, opacity, transform 100ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: currentColor;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.54);
  content: '';
  height: 3rem;
  margin-top: -0.75rem;
  margin-left: -0.75rem;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0.87, 0.87) translateZ(0);
  width: 3rem;
}

.custom-control-label::after {
  color: rgba(0, 0, 0, 0.54);
  position: absolute;
  top: 0rem;
  left: 0;
}

.custom-control-input {
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  left: 0;
  width: 1rem;
  z-index: -1;
}

.custom-control-input:not([disabled]):hover ~ .custom-control-label {
  cursor: pointer;
}

.custom-control-input:active ~ .custom-control-label::before, .custom-control-input.focus ~ .custom-control-label::before {
  opacity: 0.12;
  transform: scale(1, 1) translateZ(0);
}

.custom-control-input:checked ~ .custom-control-label::after {
  color: #6200ee;
  opacity: 1;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #6200ee;
}

.custom-control-input[disabled] ~ .custom-control-label {
  color: rgba(0, 0, 0, 0.38);
}

.custom-control-input[disabled] ~ .custom-control-label::after {
  filter: saturate(0);
  opacity: 0.26;
}

.custom-control-input[disabled] ~ .custom-control-label::before {
  display: none;
}

.custom-checkbox .custom-control-label::after {
  content: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" opacity="0.54"%3e%3cpath d="M0 0h24v24H0z" fill="none"/%3e%3cpath d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/%3e%3c/svg%3e');
}

.custom-checkbox .custom-control-input {
  /* &:indeterminate:disabled ~ .custom-control-label::after {
      opacity: 0.26;
    } */
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="%236200ee"%3e%3cpath d="M0 0h24v24H0z" fill="none"/%3e%3cpath d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/%3e%3c/svg%3e');
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  content: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px"%3e%3cg%3e%3crect fill="none" height="24" width="24"/%3e%3c/g%3e%3cg%3e%3cg%3e%3cg%3e%3cpath d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M17,13H7v-2h10V13z"/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e');
}

.custom-radio .custom-control-label::after {
  font-size: 1.5rem;
  line-height: 1.5rem;
  vertical-align: -0.362637em;
  font-family: 'Material Icons';
  font-family: var(--font-family-material-icons);
  font-feature-settings: 'liga';
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  content: "radio_button_unchecked";
  line-height: 1;
  vertical-align: middle;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  content: "radio_button_checked";
}

.custom-switch {
  align-items: center;
  display: flex;
  flex-grow: 1;
  padding-left: 36px;
}

.custom-switch .custom-control-label {
  flex: 1;
  /* &:active::before {
      opacity: 1;
    } */
}

.custom-switch .custom-control-label::before {
  background: #000000;
  border-radius: 0.4375rem;
  content: '';
  height: 0.875rem;
  opacity: 0.38;
  pointer-events: all;
  position: absolute;
  top: 15px;
  transform: none;
  transition: background-color 90ms cubic-bezier(0.4, 0, 0.2, 1), transform 90ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 2rem;
  will-change: background-color, transform;
}

.custom-switch .custom-control-label::after {
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  content: '';
  height: 1.25rem;
  position: absolute;
  left: -1rem;
  transform: translateX(0);
  transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 90ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 1.25rem;
  will-change: box-shadow, transform;
}

.custom-switch .custom-control-input:active ~ .custom-control-label::before {
  opacity: 0.38;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background: #6200ee;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background: #6200ee;
  transform: translateX(1.25rem);
}

.custom-switch .custom-control-input:not(:checked):not(:disabled) ~ .custom-control-label:hover::after {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 0 0 0.875rem rgba(0, 0, 0, 0.04);
}

.custom-switch .custom-control-input:checked:not(:disabled) ~ .custom-control-label:hover::after {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 0 0 0.875rem rgba(98, 0, 238, 0.12);
}

.custom-switch .custom-control-input:not(:checked):focus ~ .custom-control-label::after {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 0 0 0.875rem rgba(0, 0, 0, 0.08);
}

.custom-switch .custom-control-input:checked:focus ~ .custom-control-label::after {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 0 0 0.875rem rgba(98, 0, 238, 0.12);
}

.custom-switch .custom-control-input:disabled ~ .custom-control-label::before {
  background: #e0e0e0;
  display: block;
}

.custom-switch .custom-control-input:disabled ~ .custom-control-label::after {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.07), 0 2px 2px 0 rgba(0, 0, 0, 0.05), 0 1px 5px 0 rgba(0, 0, 0, 0.04);
}

.custom-switch .custom-control-input:checked:disabled ~ .custom-control-label::before {
  background: rgba(226, 208, 252, 0.8252);
}

.custom-switch .custom-control-input:checked:disabled ~ .custom-control-label::after {
  background: #c39ef9;
}

.custom-range {
  appearance: none;
  background: transparent;
  height: 3rem;
  padding: 0;
  width: 100%;
}

.custom-range:hover {
  cursor: pointer;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:active {
  outline: 0;
}

.custom-range:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 0 0 0.875rem rgba(98, 0, 238, 0.24);
}

.custom-range:active::-moz-range-thumb {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 0 0 0.875rem rgba(98, 0, 238, 0.24);
}

.custom-range:active::-ms-thumb {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 0 0 0.875rem rgba(98, 0, 238, 0.24);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  background: #6200ee;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  height: 1.25rem;
  transition: box-shadow 100ms ease-out;
  width: 1.25rem;
  appearance: none;
  margin-top: -0.5rem;
}

.custom-range::-webkit-slider-thumb:hover {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 0 0 0.875rem rgba(98, 0, 238, 0.12);
}

.custom-range::-webkit-slider-thumb:active {
  background: #6200ee;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 0 0 0.875rem rgba(98, 0, 238, 0.24);
}

.custom-range::-webkit-slider-runnable-track {
  background: rgba(98, 0, 238, 0.24);
  border-color: transparent;
  border-radius: 0.125rem;
  color: transparent;
  height: 0.25rem;
  width: 100%;
}

.custom-range::-moz-range-thumb {
  background: #6200ee;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  height: 1.25rem;
  transition: box-shadow 100ms ease-out;
  width: 1.25rem;
  -moz-appearance: none;
}

.custom-range::-moz-range-thumb:hover {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 0 0 0.875rem rgba(98, 0, 238, 0.12);
}

.custom-range::-moz-range-thumb:active {
  background: #6200ee;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 0 0 0.875rem rgba(98, 0, 238, 0.24);
}

.custom-range::-moz-range-track {
  background: rgba(98, 0, 238, 0.24);
  border-color: transparent;
  border-radius: 0.125rem;
  color: transparent;
  height: 0.25rem;
  width: 100%;
}

.custom-range::-moz-range-progress {
  background: #6200ee;
  border-radius: 0.1875rem;
  height: 0.375rem;
}

.custom-range::-ms-thumb {
  background: #6200ee;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  height: 1.25rem;
  transition: box-shadow 100ms ease-out;
  width: 1.25rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}

.custom-range::-ms-thumb:hover {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 0 0 0.875rem rgba(98, 0, 238, 0.12);
}

.custom-range::-ms-thumb:active {
  background: #6200ee;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 0 0 0.875rem rgba(98, 0, 238, 0.24);
}

.custom-range::-ms-track {
  background: rgba(98, 0, 238, 0.24);
  border-color: transparent;
  border-radius: 0.125rem;
  color: transparent;
  height: 0.25rem;
  width: 100%;
  background: transparent;
}

.custom-range::-ms-fill-lower {
  background: #6200ee;
  border-radius: 0.1875rem;
  height: 0.375rem;
}

.custom-range::-ms-fill-upper {
  background: rgba(98, 0, 238, 0.24);
  border-radius: 0.125rem;
  margin-right: 15px;
}

.custom-range:disabled {
  cursor: default;
}

.custom-range:disabled::-webkit-slider-thumb {
  background: #8c8c8c;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2);
}

.custom-range:disabled::-webkit-slider-runnable-track {
  background: #d7d7d7;
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb, .custom-range:disabled:hover::-moz-range-thumb {
  background: #8c8c8c;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2);
}

.custom-range:disabled::-moz-range-track {
  background: #d7d7d7;
  cursor: default;
}

.custom-range:disabled::-moz-range-progress {
  background: #8c8c8c;
}

.custom-range:disabled::-ms-thumb {
  background: #8c8c8c;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2);
}

.custom-range:disabled::-ms-fill-lower {
  background: #8c8c8c;
}

.custom-range:disabled::-ms-fill-upper {
  background: #d7d7d7;
  cursor: default;
}

.toast-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0.5rem;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 239;
}

.toast {
  background-color: #333333;
  border-radius: 4px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.87);
  flex: 0 0 auto;
  letter-spacing: 0.017857em;
  line-height: 1.25rem;
  max-width: 42rem;
  min-width: 21.5rem;
  opacity: 0;
  padding: 0.875rem 1rem;
  transform: scale(0.8);
  transition: opacity 0.15s cubic-bezier(0, 0, 0.2, 1), transform 0.15s cubic-bezier(0, 0, 0.2, 1);
  will-change: opacity, transform;
  z-index: 240;
}

.toast.show {
  display: block;
  opacity: 1;
  transform: scale(1);
}

.toast.hide {
  display: none;
}

.toast .close {
  align-items: flex-start;
  border-radius: 50%;
  color: rgba(255, 255, 255, 0.87);
  display: flex;
  height: 36px;
  justify-content: center;
  line-height: 0;
  margin-right: 1rem;
  padding: 9px;
  width: 36px;
}

.toast .btn, .toast .card-link {
  background: transparent;
  box-shadow: none;
  color: #bb86fc;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  padding: 0 0.5rem;
}

.toast .btn:first-of-type, .toast .card-link:first-of-type {
  margin-left: auto;
}

.toast .btn:last-of-type, .toast .card-link:last-of-type {
  margin-right: -0.5rem;
}

.toast .btn:hover, .toast .card-link:hover,
.toast .close:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.toast .btn:active, .toast .card-link:active,
.toast .close:active {
  background-color: rgba(255, 255, 255, 0.32);
}

.toast-header,
.toast-body {
  align-items: center;
  display: flex;
}

.toast-header + .toast-body {
  padding-top: 1rem;
}

.stepper {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  padding: 1.5rem 1.5rem;
  position: relative;
}

.stepper::after, .stepper::before {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  content: '';
  position: absolute;
}

.stepper:first-child::before {
  display: none;
}

.stepper:last-child::after {
  display: none;
}

.stepper-horiz {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}

.stepper-horiz::before {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  content: '';
  position: absolute;
  top: 50%;
  right: 1.5rem;
  left: 1.5rem;
}

.stepper-horiz .stepper::after, .stepper-horiz .stepper::before {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  top: 50%;
  width: 1rem;
}

.stepper-horiz .stepper::after {
  right: 0;
}

.stepper-horiz .stepper::before {
  left: 0;
}

.stepper-vert {
  background-color: #ffffff;
  position: relative;
}

.stepper-vert .stepper::after, .stepper-vert .stepper::before {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  height: 1rem;
  left: 2.25rem;
}

.stepper-vert .stepper::after {
  bottom: 0;
}

.stepper-vert .stepper::before {
  top: 0;
}

.stepper-icon {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.38);
  border-radius: 50%;
  color: white;
  display: inline-flex;
  font-size: 0.75rem;
  height: 1.5rem;
  justify-content: center;
  margin-right: 0.5rem;
  position: relative;
  width: 1.5rem;
}

.stepper.active .stepper-icon,
.stepper.done .stepper-icon {
  background-color: #6200ee;
  color: white;
}

.stepper-icon i,
.stepper-icon [class*='material-icons'] {
  font-size: 1rem;
}

.stepper-text {
  color: rgba(0, 0, 0, 0.38);
  font-size: 0.875rem;
  font-weight: 400;
  position: relative;
}

.stepper.active .stepper-text,
.stepper.done .stepper-text {
  color: rgba(0, 0, 0, 0.87);
}

.stepper.active .stepper-text {
  font-weight: 500;
}

.nav-tabs {
  flex-wrap: nowrap;
  width: 100%;
}

.nav-tabs.nav-inverse .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.nav-tabs.nav-inverse .nav-link.active {
  color: #ffffff;
}

.nav-tabs.nav-inverse .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.nav-tabs.nav-inverse .nav-link:focus {
  background-color: rgba(255, 255, 255, 0.24);
}

.nav-tabs.nav-inverse .nav-link:active {
  background-color: rgba(255, 255, 255, 0.32);
}

.nav-tabs.nav-inverse .nav-link::before {
  background-color: #ffffff;
}

.nav-tabs .nav-link {
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: background-color, color, opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  flex: 1 0 auto;
  font-size: 0.875rem;
  font-weight: 500;
  height: 3rem;
  justify-content: center;
  letter-spacing: 0.089286em;
  line-height: 2.25rem;
  max-width: 22.5rem;
  padding: 0 1rem;
  position: relative;
  text-transform: uppercase;
  /* &:active {
      background-color: rgba(selection-theme-color(), $overlay-pressed-white-opacity);
      color: selection-theme-color(); // testing ripple
      opacity: 1;
    } */
}

@media (min-width: 576px) {
  .nav-tabs .nav-link {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .nav-tabs .nav-link {
    transition-duration: 280ms;
  }
}

@media (min-width: 1200px) {
  .nav-tabs .nav-link {
    padding: 0 1.5rem;
  }
}

.nav-tabs .nav-link::before {
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #6200ee;
  content: '';
  height: 0.125rem;
  opacity: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (min-width: 576px) {
  .nav-tabs .nav-link::before {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .nav-tabs .nav-link::before {
    transition-duration: 280ms;
  }
}

.nav-tabs .nav-link:hover {
  background-color: rgba(98, 0, 238, 0.04);
}

.nav-tabs .nav-link:focus {
  background-color: rgba(98, 0, 238, 0.12);
}

.nav-tabs .nav-link.flex-column {
  height: 4.5rem;
}

.nav-tabs .nav-link.flex-column i,
.nav-tabs .nav-link.flex-column [class*='material-icons'] {
  margin: .75rem 0 -3px;
}

.nav-tabs .nav-link.active {
  color: #6200ee;
}

.nav-tabs .nav-link.active::before {
  opacity: 1;
}

.nav-tabs .nav-link.disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}

.nav-tabs .nav-link i,
.nav-tabs .nav-link [class*='material-icons'] {
  margin-right: 0.5rem;
}

.nav-tabs .nav-item.show .nav-link {
  background-color: rgba(0, 0, 0, 0.12);
}

.nav-tabs-material {
  position: relative;
}

.nav-tabs-material.animate .nav-link::before {
  opacity: 0;
}

.nav-tabs-material.animate .nav-tabs-indicator {
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: left, right 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

@media (min-width: 576px) {
  .nav-tabs-material.animate .nav-tabs-indicator {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .nav-tabs-material.animate .nav-tabs-indicator {
    transition-duration: 280ms;
  }
}

.nav-tabs-material.nav-inverse .nav-tabs-indicator {
  background-color: #ffffff;
}

.nav-tabs-material .nav-link {
  min-width: 5.625rem;
}

.nav-tabs-material .nav-link::before {
  transition: none;
}

.nav-tabs-material .nav-tabs-indicator {
  background-color: #6200ee;
  display: none;
  height: 0.125rem;
  position: absolute;
  bottom: 0;
}

.nav-tabs-material .nav-tabs-indicator.show {
  display: block;
}

.nav-tabs-scrollable .nav-tabs-material .nav-tabs-indicator {
  bottom: 3rem;
}

.nav-tabs-scrollable .nav-tabs {
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 2.5rem;
}

fieldset {
  border: 0;
  margin-bottom: 1.5rem;
  padding: 0;
}

legend {
  margin-bottom: 0.5rem;
}

.form-control, .custom-select, .form-control-file {
  background-clip: padding-box;
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.42);
  border-radius: 0;
  border-style: solid;
  border-width: 0 0 1px;
  box-shadow: none;
  caret-color: #6200ee;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 1rem;
  letter-spacing: 0.009375em;
  line-height: 1.5;
  padding: 0.375rem 0 calc(0.375rem - 1px);
  transition: border-color 15ms linear, box-shadow 180ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
}

.form-control::-ms-expand, .custom-select::-ms-expand, .form-control-file::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring, .custom-select:-moz-focusring, .form-control-file:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0.87);
}

.form-control::placeholder, .custom-select::placeholder, .form-control-file::placeholder {
  color: rgba(0, 0, 0, 0.54);
  opacity: 1;
  /* needed for Firefox */
}

.form-control:disabled, .custom-select:disabled, .form-control-file:disabled, .form-control[readonly], .custom-select[readonly], .form-control-file[readonly] {
  border-style: dotted;
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}

.form-control:disabled:hover, .custom-select:disabled:hover, .form-control-file:disabled:hover, .form-control:disabled:focus, .custom-select:disabled:focus, .form-control-file:disabled:focus, .form-control[readonly]:hover, .custom-select[readonly]:hover, .form-control-file[readonly]:hover, .form-control[readonly]:focus, .custom-select[readonly]:focus, .form-control-file[readonly]:focus {
  border-color: rgba(0, 0, 0, 0.42);
  box-shadow: none;
}

.form-control:hover, .custom-select:hover, .form-control-file:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.form-control:focus, .custom-select:focus, .form-control-file:focus {
  border-color: #6200ee;
  box-shadow: inset 0 -2px 0 -1px #6200ee;
  outline: 0;
}

.form-control:invalid:required, .custom-select:invalid:required, .form-control-file:invalid:required {
  outline: 0;
}

.form-control[type='file'] {
  max-height: 2.25rem;
}

input[type='date'].form-control,
input[type='time'].form-control,
input[type='datetime-local'].form-control,
input[type='month'].form-control {
  appearance: none;
}

.form-control-lg {
  font-size: 2.125rem;
  line-height: 1.176471;
  padding: 0.625rem 0 calc(0.625rem - 1px);
}

.form-control-lg[type='file'] {
  max-height: 3.75rem;
}

.form-control-sm {
  font-size: 0.8125rem;
  line-height: 1.538462;
  padding: 0.375rem 0 calc(0.375rem - 1px);
}

.form-control-sm[type='file'] {
  max-height: 2rem;
}

select.form-control, select.custom-select {
  appearance: none;
  background: url('data:image/svg+xml,%3csvg fill="%23000000" fill-opacity="0.54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3e%3cpath d="M7 10l5 5 5-5z"/%3e%3c/svg%3e') 100% 50%/1.5em 1.5em no-repeat;
  padding-right: 1.5em;
  transition: background-image 180ms;
}

select.form-control[multiple], select.custom-select[multiple], select.form-control[size]:not([size='1']), select.custom-select[size]:not([size='1']) {
  background-image: none;
}

select.form-control[multiple], select.form-control[size]:not([size='1']), select.custom-select[multiple], select.custom-select[size]:not([size='1']), textarea.form-control:not([rows='1']) {
  background-image: none !important;
  border-color: rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  border-width: 1px;
  min-height: 3.5rem;
  padding: calc(1rem - 1px) 1rem;
}

select.form-control:hover[multiple], select.form-control:hover[size]:not([size='1']), select.custom-select:hover[multiple], select.custom-select:hover[size]:not([size='1']), textarea.form-control:hover:not([rows='1']) {
  border-color: rgba(0, 0, 0, 0.87);
}

select.form-control:focus[multiple], select.form-control:focus[size]:not([size='1']), select.custom-select:focus[multiple], select.custom-select:focus[size]:not([size='1']), textarea.form-control:focus:not([rows='1']) {
  border-color: #6200ee;
  box-shadow: inset 2px 2px 0 -1px #6200ee, inset -2px -2px 0 -1px #6200ee;
}

select.form-control[multiple], select.form-control[size]:not([size='1']), select.custom-select[multiple], select.custom-select[size]:not([size='1']) {
  padding: 0.5rem 0;
}

select.form-control[multiple] option, select.form-control[size]:not([size='1']) option, select.custom-select[multiple] option, select.custom-select[size]:not([size='1']) option {
  padding: 0 1.5rem;
}

select.form-control option, select.custom-select option {
  align-items: center;
  display: flex;
  font-size: 1rem;
  height: 3rem;
  letter-spacing: 0.009375em;
}

select.form-control option:hover, select.custom-select option:hover {
  background: rgba(0, 0, 0, 0.04);
}

select.form-control option:active, select.custom-select option:active {
  background: rgba(0, 0, 0, 0.12) !important;
}

select.form-control:focus, select.custom-select:focus {
  background-image: url('data:image/svg+xml;charset=utf8,%3csvg fill="%236200ee" transform="rotate(180)" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3e%3cpath d="M7 10l5 5 5-5z"/%3e%3canimateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 0 0" to="180 0 0" dur="250ms"/%3e%3c/svg%3e');
}

select.form-control-lg[multiple], select.form-control-lg[size]:not([size='1']) {
  padding: calc(0.875rem - 1px) 1rem;
}

select.form-control-sm[multiple], select.form-control-sm[size]:not([size='1']) {
  padding: calc(0.75rem - 1px) 0.75rem;
}

textarea.form-control {
  min-height: 2.25rem;
}

textarea.form-control-lg {
  min-height: 3.75rem;
}

textarea.form-control-lg:not([rows='1']) {
  min-height: 4.25rem;
  padding: calc(0.875rem - 1px) 1rem;
}

textarea.form-control-sm {
  min-height: 2rem;
}

textarea.form-control-sm:not([rows='1']) {
  min-height: 2.75rem;
  padding: calc(0.75rem - 1px) 0.75rem;
}

.custom-file {
  display: inline-block;
  height: 2.25rem;
  margin-bottom: 0;
  position: relative;
  width: 100%;
}

.custom-file-input {
  height: 2.25rem;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 2;
}

.custom-file-input:hover ~ .custom-file-label, .custom-file-input:focus ~ .custom-file-label {
  border-bottom-color: #6200ee;
  box-shadow: inset 0 -2px 0 -1px #6200ee;
}

.custom-file-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: border-color, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  color: rgba(0, 0, 0, 0.38);
  font-size: 1rem;
  height: 2.25rem;
  line-height: 1.5;
  overflow: hidden;
  padding: 0.375rem 2.25rem calc(0.375rem - 1px) 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

@media (min-width: 576px) {
  .custom-file-label {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .custom-file-label {
    transition-duration: 280ms;
  }
}

.custom-file-label::after {
  font-size: 1.5rem;
  line-height: 1.5rem;
  vertical-align: -0.362637em;
  font-family: 'Material Icons';
  font-family: var(--font-family-material-icons);
  font-feature-settings: 'liga';
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  content: "attachment";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.custom-select {
  /* @extend %form-select;

  &[multiple],
  &[size]:not([size='1']) {
    @extend %form-textarea;
  } */
}

.custom-select-sm {
  font-size: 0.8125rem;
  line-height: 1.538462;
  padding: 0.375rem 1.5em calc(0.375rem - 1px) 0;
}

.custom-select-sm[multiple], .custom-select-sm[size]:not([size='1']) {
  padding: calc(0.75rem - 1px) 0.75rem;
}

.custom-select-lg {
  font-size: 2.125rem;
  line-height: 1.176471;
  padding: 0.625rem 1.5em calc(0.625rem - 1px) 0;
}

.custom-select-lg[multiple], .custom-select-lg[size]:not([size='1']) {
  padding: calc(0.875rem - 1px) 1rem;
}

.form-control-file {
  max-height: 2.25rem;
}

.form-control-range {
  display: block;
  width: 100%;
}

[class*='valid-feedback'] {
  display: none;
  width: 100%;
}

.valid-feedback {
  color: #61d800;
}

.valid-tooltip {
  border-radius: 4px;
  background-color: #61d800;
  color: white;
  display: none;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-top: 0.5rem;
  max-width: 100%;
  opacity: 0.9;
  padding: 0 1rem;
  position: absolute;
  top: 100%;
  left: 0;
  text-align: center;
  word-break: break-word;
  z-index: 240;
}

@media (min-width: 768px) {
  .valid-tooltip {
    font-size: 0.625rem;
    padding: 0 0.5rem;
  }
}

.form-row > .col > .valid-tooltip,
.form-row > [class*='col-'] > .valid-tooltip {
  left: 5px;
}

.form-control-lg + .valid-tooltip {
  margin-top: 0.75rem;
}

.form-control-sm + .valid-tooltip {
  margin-top: 0.25rem;
}

.custom-control-input.is-valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label::after,
.was-validated .custom-control-input:valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label::after {
  color: #61d800;
}

.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  background-color: #61d800;
}

.custom-control-input.is-valid ~ .custom-control-track,
.was-validated .custom-control-input:valid ~ .custom-control-track {
  background-color: rgba(97, 216, 0, 0.5);
}

.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-file-input.is-valid:hover ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:hover ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-bottom-color: #61d800;
  box-shadow: inset 0 -2px 0 -1px #61d800;
}

.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-bottom-color: #61d800;
}

.custom-file-input.is-valid ~ .custom-file-label:hover,
.was-validated .custom-file-input:valid ~ .custom-file-label:hover {
  border-bottom-color: #61d800;
  box-shadow: inset 0 -2px 0 -1px #61d800;
}

.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-switch .custom-control-input.is-valid ~ .custom-control-label::after,
.was-validated .custom-switch .custom-control-input:valid ~ .custom-control-label::after {
  background-color: #61d800;
}

.form-check-input.is-valid + .form-check-label,
.was-validated .form-check-input:valid + .form-check-label {
  color: #61d800;
}

.is-valid.form-control, .is-valid.custom-select, .is-valid.form-control-file,
.was-validated .form-control:valid,
.was-validated .custom-select:valid,
.was-validated .form-control-file:valid {
  border-color: #61d800;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath fill='%2361d800' d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3e%3c/svg%3e");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
  padding-right: 0;
}

.is-valid.form-control:hover, .is-valid.custom-select:hover, .is-valid.form-control-file:hover, .is-valid.form-control:focus, .is-valid.custom-select:focus, .is-valid.form-control-file:focus,
.was-validated .form-control:valid:hover,
.was-validated .custom-select:valid:hover,
.was-validated .form-control-file:valid:hover,
.was-validated .form-control:valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control-file:valid:focus {
  border-color: #61d800;
}

.is-valid.form-control:focus, .is-valid.custom-select:focus, .is-valid.form-control-file:focus,
.was-validated .form-control:valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control-file:valid:focus {
  box-shadow: inset 0 -2px 0 -1px #61d800;
}

.is-valid.form-control ~ .valid-feedback, .is-valid.custom-select ~ .valid-feedback, .is-valid.form-control-file ~ .valid-feedback,
.is-valid.form-control ~ .valid-tooltip,
.is-valid.custom-select ~ .valid-tooltip,
.is-valid.form-control-file ~ .valid-tooltip,
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-tooltip,
.was-validated .form-control-file:valid ~ .valid-tooltip {
  display: block;
}

select.is-valid.form-control:hover[multiple], select.is-valid.form-control:hover[size]:not([size='1']), select.is-valid.custom-select:hover[multiple], select.is-valid.custom-select:hover[size]:not([size='1']), textarea.is-valid.form-control:hover:not([rows='1']), select.is-valid.form-control:focus[multiple], select.is-valid.form-control:focus[size]:not([size='1']), select.is-valid.custom-select:focus[multiple], select.is-valid.custom-select:focus[size]:not([size='1']), textarea.is-valid.form-control:focus:not([rows='1']),
.was-validated select.form-control:valid:hover[multiple],
.was-validated select.form-control:valid:hover[size]:not([size='1']),
.was-validated select.custom-select:valid:hover[multiple],
.was-validated select.custom-select:valid:hover[size]:not([size='1']),
.was-validated textarea.form-control:valid:hover:not([rows='1']),
.was-validated select.form-control:valid:focus[multiple],
.was-validated select.form-control:valid:focus[size]:not([size='1']),
.was-validated select.custom-select:valid:focus[multiple],
.was-validated select.custom-select:valid:focus[size]:not([size='1']),
.was-validated textarea.form-control:valid:focus:not([rows='1']) {
  box-shadow: inset 2px 2px 0 -1px #61d800, inset -2px -2px 0 -1px #61d800;
}

.textfield-box select.is-valid.form-control:hover[multiple], .textfield-box select.is-valid.form-control:hover[size]:not([size='1']), .textfield-box select.is-valid.custom-select:hover[multiple], .textfield-box select.is-valid.custom-select:hover[size]:not([size='1']), .textfield-box textarea.is-valid.form-control:hover:not([rows='1']), .textfield-box select.is-valid.form-control:focus[multiple], .textfield-box select.is-valid.form-control:focus[size]:not([size='1']), .textfield-box select.is-valid.custom-select:focus[multiple], .textfield-box select.is-valid.custom-select:focus[size]:not([size='1']), .textfield-box textarea.is-valid.form-control:focus:not([rows='1']),
.was-validated .textfield-box select.form-control:valid:hover[multiple],
.was-validated .textfield-box select.form-control:valid:hover[size]:not([size='1']),
.was-validated .textfield-box select.custom-select:valid:hover[multiple],
.was-validated .textfield-box select.custom-select:valid:hover[size]:not([size='1']),
.was-validated .textfield-box textarea.form-control:valid:hover:not([rows='1']),
.was-validated .textfield-box select.form-control:valid:focus[multiple],
.was-validated .textfield-box select.form-control:valid:focus[size]:not([size='1']),
.was-validated .textfield-box select.custom-select:valid:focus[multiple],
.was-validated .textfield-box select.custom-select:valid:focus[size]:not([size='1']),
.was-validated .textfield-box textarea.form-control:valid:focus:not([rows='1']) {
  box-shadow: inset 0 -2px 0 -1px #61d800;
}

[class*='valid-feedback'] {
  display: none;
  width: 100%;
}

.invalid-feedback {
  color: #b00020;
}

.invalid-tooltip {
  border-radius: 4px;
  background-color: #b00020;
  color: white;
  display: none;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-top: 0.5rem;
  max-width: 100%;
  opacity: 0.9;
  padding: 0 1rem;
  position: absolute;
  top: 100%;
  left: 0;
  text-align: center;
  word-break: break-word;
  z-index: 240;
}

@media (min-width: 768px) {
  .invalid-tooltip {
    font-size: 0.625rem;
    padding: 0 0.5rem;
  }
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*='col-'] > .invalid-tooltip {
  left: 5px;
}

.form-control-lg + .invalid-tooltip {
  margin-top: 0.75rem;
}

.form-control-sm + .invalid-tooltip {
  margin-top: 0.25rem;
}

.custom-control-input.is-invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label::after,
.was-validated .custom-control-input:invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label::after {
  color: #b00020;
}

.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  background-color: #b00020;
}

.custom-control-input.is-invalid ~ .custom-control-track,
.was-validated .custom-control-input:invalid ~ .custom-control-track {
  background-color: rgba(176, 0, 32, 0.5);
}

.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-file-input.is-invalid:hover ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:hover ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-bottom-color: #b00020;
  box-shadow: inset 0 -2px 0 -1px #b00020;
}

.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-bottom-color: #b00020;
}

.custom-file-input.is-invalid ~ .custom-file-label:hover,
.was-validated .custom-file-input:invalid ~ .custom-file-label:hover {
  border-bottom-color: #b00020;
  box-shadow: inset 0 -2px 0 -1px #b00020;
}

.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-switch .custom-control-input.is-invalid ~ .custom-control-label::after,
.was-validated .custom-switch .custom-control-input:invalid ~ .custom-control-label::after {
  background-color: #b00020;
}

.form-check-input.is-invalid + .form-check-label,
.was-validated .form-check-input:invalid + .form-check-label {
  color: #b00020;
}

.is-invalid.form-control, .is-invalid.custom-select, .is-invalid.form-control-file,
.was-validated .form-control:invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control-file:invalid {
  border-color: #b00020;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath fill='%23b00020' d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'/%3e%3c/svg%3e");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
  padding-right: 0;
}

.is-invalid.form-control:hover, .is-invalid.custom-select:hover, .is-invalid.form-control-file:hover, .is-invalid.form-control:focus, .is-invalid.custom-select:focus, .is-invalid.form-control-file:focus,
.was-validated .form-control:invalid:hover,
.was-validated .custom-select:invalid:hover,
.was-validated .form-control-file:invalid:hover,
.was-validated .form-control:invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control-file:invalid:focus {
  border-color: #b00020;
}

.is-invalid.form-control:focus, .is-invalid.custom-select:focus, .is-invalid.form-control-file:focus,
.was-validated .form-control:invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control-file:invalid:focus {
  box-shadow: inset 0 -2px 0 -1px #b00020;
}

.is-invalid.form-control ~ .invalid-feedback, .is-invalid.custom-select ~ .invalid-feedback, .is-invalid.form-control-file ~ .invalid-feedback,
.is-invalid.form-control ~ .invalid-tooltip,
.is-invalid.custom-select ~ .invalid-tooltip,
.is-invalid.form-control-file ~ .invalid-tooltip,
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.was-validated .form-control-file:invalid ~ .invalid-tooltip {
  display: block;
}

select.is-invalid.form-control:hover[multiple], select.is-invalid.form-control:hover[size]:not([size='1']), select.is-invalid.custom-select:hover[multiple], select.is-invalid.custom-select:hover[size]:not([size='1']), textarea.is-invalid.form-control:hover:not([rows='1']), select.is-invalid.form-control:focus[multiple], select.is-invalid.form-control:focus[size]:not([size='1']), select.is-invalid.custom-select:focus[multiple], select.is-invalid.custom-select:focus[size]:not([size='1']), textarea.is-invalid.form-control:focus:not([rows='1']),
.was-validated select.form-control:invalid:hover[multiple],
.was-validated select.form-control:invalid:hover[size]:not([size='1']),
.was-validated select.custom-select:invalid:hover[multiple],
.was-validated select.custom-select:invalid:hover[size]:not([size='1']),
.was-validated textarea.form-control:invalid:hover:not([rows='1']),
.was-validated select.form-control:invalid:focus[multiple],
.was-validated select.form-control:invalid:focus[size]:not([size='1']),
.was-validated select.custom-select:invalid:focus[multiple],
.was-validated select.custom-select:invalid:focus[size]:not([size='1']),
.was-validated textarea.form-control:invalid:focus:not([rows='1']) {
  box-shadow: inset 2px 2px 0 -1px #b00020, inset -2px -2px 0 -1px #b00020;
}

.textfield-box select.is-invalid.form-control:hover[multiple], .textfield-box select.is-invalid.form-control:hover[size]:not([size='1']), .textfield-box select.is-invalid.custom-select:hover[multiple], .textfield-box select.is-invalid.custom-select:hover[size]:not([size='1']), .textfield-box textarea.is-invalid.form-control:hover:not([rows='1']), .textfield-box select.is-invalid.form-control:focus[multiple], .textfield-box select.is-invalid.form-control:focus[size]:not([size='1']), .textfield-box select.is-invalid.custom-select:focus[multiple], .textfield-box select.is-invalid.custom-select:focus[size]:not([size='1']), .textfield-box textarea.is-invalid.form-control:focus:not([rows='1']),
.was-validated .textfield-box select.form-control:invalid:hover[multiple],
.was-validated .textfield-box select.form-control:invalid:hover[size]:not([size='1']),
.was-validated .textfield-box select.custom-select:invalid:hover[multiple],
.was-validated .textfield-box select.custom-select:invalid:hover[size]:not([size='1']),
.was-validated .textfield-box textarea.form-control:invalid:hover:not([rows='1']),
.was-validated .textfield-box select.form-control:invalid:focus[multiple],
.was-validated .textfield-box select.form-control:invalid:focus[size]:not([size='1']),
.was-validated .textfield-box select.custom-select:invalid:focus[multiple],
.was-validated .textfield-box select.custom-select:invalid:focus[size]:not([size='1']),
.was-validated .textfield-box textarea.form-control:invalid:focus:not([rows='1']) {
  box-shadow: inset 0 -2px 0 -1px #b00020;
}

.form-control[class*='valid'] {
  padding-right: calc(1.5rem + 0.5rem);
}

[class*='form-ripple']:focus-within label:not(.custom-control-label):not(.form-check-label):not(.btn):not(.card-link), [class*='form-ripple']:focus-within legend:not(.custom-control-label):not(.form-check-label):not(.btn):not(.card-link) {
  color: #6200ee;
}

[class*='form-ripple'],
.floating-label {
  position: relative;
}

[class*='form-ripple']::before,
.floating-label::before {
  background: #6200ee;
  content: '';
  height: 2px;
  position: absolute;
  left: 0;
  transform: scaleX(0);
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  will-change: transform;
  z-index: 3;
}

[class*='form-ripple'] .form-control:focus,
.floating-label .form-control:focus {
  box-shadow: none;
}

[class*='form-ripple']:focus-within::before {
  transform: scaleX(1);
}

.form-ripple::before {
  top: calc(57px - 2px);
}

.form-ripple-lg::before {
  top: calc(82px - 2px);
}

.form-ripple-sm::before {
  top: calc(53px - 2px);
}

.form-ripple-nolabel::before {
  top: calc(36px - 2px);
}

.form-ripple-lg-nolabel::before {
  top: calc(61px - 2px);
}

.form-ripple-sm-nolabel::before {
  top: calc(32px - 2px);
}

.form-ripple-bottom::before {
  bottom: 0 !important;
}

.textfield-box .form-control, .textfield-box .custom-select, .textfield-box .form-control-file {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 4px 4px 0 0;
  padding: 1rem 1rem calc(1rem - 1px);
  transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

.textfield-box .form-control::placeholder, .textfield-box .custom-select::placeholder, .textfield-box .form-control-file::placeholder {
  color: rgba(0, 0, 0, 0.6);
  opacity: 1;
  /* needed for Firefox */
}

.textfield-box .form-control:hover, .textfield-box .custom-select:hover, .textfield-box .form-control-file:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.textfield-box .form-control:focus, .textfield-box .custom-select:focus, .textfield-box .form-control-file:focus {
  background-color: rgba(0, 0, 0, 0.14);
}

.textfield-box select.form-control, .textfield-box select.custom-select {
  padding-right: 1.5em;
}

.textfield-box select.form-control[multiple], .textfield-box select.form-control[size]:not([size='1']), .textfield-box select.custom-select[multiple], .textfield-box select.custom-select[size]:not([size='1']), .textfield-box textarea.form-control:not([rows='1']) {
  border-radius: 4px 4px 0 0;
  border-width: 0 0 1px;
  padding: 1rem 1rem calc(1rem - 1px);
}

.textfield-box select.form-control:hover[multiple], .textfield-box select.form-control:hover[size]:not([size='1']), .textfield-box select.custom-select:hover[multiple], .textfield-box select.custom-select:hover[size]:not([size='1']), .textfield-box textarea.form-control:hover:not([rows='1']) {
  border-color: rgba(0, 0, 0, 0.87);
}

.textfield-box select.form-control:focus[multiple], .textfield-box select.form-control:focus[size]:not([size='1']), .textfield-box select.custom-select:focus[multiple], .textfield-box select.custom-select:focus[size]:not([size='1']), .textfield-box textarea.form-control:focus:not([rows='1']) {
  border-color: #6200ee;
  box-shadow: inset 0 -2px 0 -1px #6200ee;
}

.textfield-box textarea.form-control {
  min-height: 3.5rem;
}

.textfield-box .form-control[type='file'],
.textfield-box .form-control-file {
  max-height: 3.5rem;
}

.textfield-box-lg .form-control, .input-group-lg > .textfield-box .form-control, .textfield-box-lg .custom-select, .input-group-lg > .textfield-box .custom-select, .textfield-box-lg .form-control-file, .input-group-lg > .textfield-box .form-control-file {
  font-size: 2.125rem;
  line-height: 1.176471;
  padding: 0.875rem 1rem calc(0.875rem - 1px);
}

.textfield-box-lg select.form-control[multiple], .input-group-lg > .textfield-box select.form-control[multiple], .textfield-box-lg select.form-control[size]:not([size='1']), .input-group-lg > .textfield-box select.form-control[size]:not([size='1']), .textfield-box-lg select.custom-select[multiple], .input-group-lg > .textfield-box select.custom-select[multiple], .textfield-box-lg select.custom-select[size]:not([size='1']), .input-group-lg > .textfield-box select.custom-select[size]:not([size='1']), .textfield-box-lg textarea.form-control:not([rows='1']), .input-group-lg > .textfield-box textarea.form-control:not([rows='1']) {
  padding: 0.875rem 1rem calc(0.875rem - 1px);
}

.textfield-box-lg textarea.form-control, .input-group-lg > .textfield-box textarea.form-control {
  min-height: 4.25rem;
}

.textfield-box-lg .custom-select, .input-group-lg > .textfield-box .custom-select {
  padding-right: 1.5em;
}

.textfield-box-lg .form-control[type='file'], .input-group-lg > .textfield-box .form-control[type='file'],
.textfield-box-lg .form-control-file,
.input-group-lg > .textfield-box .form-control-file {
  max-height: 4.25rem;
}

.textfield-box-sm .form-control, .input-group-sm > .textfield-box .form-control, .textfield-box-sm .custom-select, .input-group-sm > .textfield-box .custom-select, .textfield-box-sm .form-control-file, .input-group-sm > .textfield-box .form-control-file {
  font-size: 0.8125rem;
  line-height: 1.538462;
  padding: 0.75rem 0.75rem calc(0.75rem - 1px);
}

.textfield-box-sm select.form-control[multiple], .input-group-sm > .textfield-box select.form-control[multiple], .textfield-box-sm select.form-control[size]:not([size='1']), .input-group-sm > .textfield-box select.form-control[size]:not([size='1']), .textfield-box-sm select.custom-select[multiple], .input-group-sm > .textfield-box select.custom-select[multiple], .textfield-box-sm select.custom-select[size]:not([size='1']), .input-group-sm > .textfield-box select.custom-select[size]:not([size='1']), .textfield-box-sm textarea.form-control:not([rows='1']), .input-group-sm > .textfield-box textarea.form-control:not([rows='1']) {
  padding: 0.75rem 0.75rem calc(0.75rem - 1px);
}

.textfield-box-sm textarea.form-control, .input-group-sm > .textfield-box textarea.form-control {
  min-height: 2.75rem;
}

.textfield-box-sm .custom-select, .input-group-sm > .textfield-box .custom-select {
  padding-right: 1.5em;
}

.textfield-box-sm .form-control[type='file'], .input-group-sm > .textfield-box .form-control[type='file'],
.textfield-box-sm .form-control-file,
.input-group-sm > .textfield-box .form-control-file {
  max-height: 2.75rem;
}

.textfield-box.form-ripple-nolabel::before,
.floating-label.textfield-box.form-ripple::before {
  top: calc(3.5rem - 2px);
}

.textfield-box-lg.form-ripple-nolabel::before, .input-group-lg > .form-ripple-nolabel.textfield-box::before,
.floating-label-lg.textfield-box.form-ripple::before,
.input-group-lg > .textfield-box.form-ripple.floating-label::before {
  top: calc(4.25rem - 1px);
}

.textfield-box-sm.form-ripple-nolabel::before, .input-group-sm > .form-ripple-nolabel.textfield-box::before,
.floating-label-sm.textfield-box.form-ripple::before,
.input-group-sm > .textfield-box.form-ripple.floating-label::before {
  top: calc(2.75rem - 2px);
}

.textfield-box.form-ripple::before {
  top: calc(85px - 2px);
}

.textfield-box-lg.form-ripple::before, .input-group-lg > .form-ripple.textfield-box::before {
  top: calc(98px - 2px);
}

.textfield-box-sm.form-ripple::before, .input-group-sm > .form-ripple.textfield-box::before {
  top: calc(73px - 2px);
}

.floating-label {
  padding-top: 0.75rem;
  position: relative;
}

.floating-label.has-value label, .floating-label.has-value legend,
.floating-label.is-focused label,
.floating-label.is-focused legend {
  top: 0;
  transform: scale(0.75);
}

.floating-label:not(.has-value):not(.is-focused) .form-control[type='date'], .floating-label:not(.has-value):not(.is-focused) .form-control[type='datetime-local'], .floating-label:not(.has-value):not(.is-focused) .form-control[type='time'] {
  color: transparent;
}

.floating-label label, .floating-label legend {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  letter-spacing: 0.009375em;
  line-height: 1.15;
  margin: 0;
  max-width: 98%;
  padding: 0;
  position: absolute;
  top: 1.125rem;
  left: 0;
  transform-origin: left top;
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1), color 0.15s cubic-bezier(0.4, 0, 0.2, 1), top 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

.floating-label .form-control, .floating-label .custom-select, .floating-label .form-control-file {
  position: relative;
}

.floating-label .form-control:focus::placeholder, .floating-label .custom-select:focus::placeholder, .floating-label .form-control-file:focus::placeholder {
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
  /* needed for Firefox */
}

.floating-label .form-control::placeholder, .floating-label .custom-select::placeholder, .floating-label .form-control-file::placeholder {
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  color: transparent;
  opacity: 0;
}

@media (min-width: 576px) {
  .floating-label .form-control::placeholder, .floating-label .custom-select::placeholder, .floating-label .form-control-file::placeholder {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .floating-label .form-control::placeholder, .floating-label .custom-select::placeholder, .floating-label .form-control-file::placeholder {
    transition-duration: 280ms;
  }
}

.floating-label-lg.has-value label, .input-group-lg > .has-value.floating-label label, .floating-label-lg.has-value legend, .input-group-lg > .has-value.floating-label legend,
.floating-label-lg.is-focused label,
.input-group-lg > .is-focused.floating-label label,
.floating-label-lg.is-focused legend,
.input-group-lg > .is-focused.floating-label legend {
  transform: scale(0.411765);
}

.floating-label-lg label, .input-group-lg > .floating-label label, .floating-label-lg legend, .input-group-lg > .floating-label legend {
  font-size: 2.125rem;
  line-height: 1.176471;
  top: 1.5rem;
}

.floating-label-lg .form-control, .input-group-lg > .floating-label .form-control, .floating-label-lg .custom-select, .input-group-lg > .floating-label .custom-select, .floating-label-lg .form-control-file, .input-group-lg > .floating-label .form-control-file {
  font-size: 2.125rem;
  line-height: 1.176471;
  padding: 0.625rem 0 calc(0.625rem - 1px);
}

.floating-label-lg select.form-control, .input-group-lg > .floating-label select.form-control, .floating-label-lg select.custom-select, .input-group-lg > .floating-label select.custom-select {
  padding-right: 1.5em;
}

.floating-label-lg select.form-control[multiple], .input-group-lg > .floating-label select.form-control[multiple], .floating-label-lg select.form-control[size]:not([size='1']), .input-group-lg > .floating-label select.form-control[size]:not([size='1']), .floating-label-lg select.custom-select[multiple], .input-group-lg > .floating-label select.custom-select[multiple], .floating-label-lg select.custom-select[size]:not([size='1']), .input-group-lg > .floating-label select.custom-select[size]:not([size='1']), .floating-label-lg textarea.form-control:not([rows='1']), .input-group-lg > .floating-label textarea.form-control:not([rows='1']) {
  padding: 0.875rem 1rem calc(0.875rem - 1px);
}

.floating-label-sm.has-value label, .input-group-sm > .has-value.floating-label label, .floating-label-sm.has-value legend, .input-group-sm > .has-value.floating-label legend,
.floating-label-sm.is-focused label,
.input-group-sm > .is-focused.floating-label label,
.floating-label-sm.is-focused legend,
.input-group-sm > .is-focused.floating-label legend {
  transform: scale(0.769231);
}

.floating-label-sm label, .input-group-sm > .floating-label label, .floating-label-sm legend, .input-group-sm > .floating-label legend {
  font-size: 0.8125rem;
  line-height: 1.538462;
  top: 1.0rem;
}

.floating-label-sm .form-control, .input-group-sm > .floating-label .form-control, .floating-label-sm .custom-select, .input-group-sm > .floating-label .custom-select, .floating-label-sm .form-control-file, .input-group-sm > .floating-label .form-control-file {
  font-size: 0.8125rem;
  line-height: 1.538462;
  padding: 0.375rem 0 calc(0.375rem - 1px);
}

.floating-label-sm select.form-control, .input-group-sm > .floating-label select.form-control, .floating-label-sm select.custom-select, .input-group-sm > .floating-label select.custom-select {
  padding-right: 1.5em;
}

.floating-label-sm select.form-control[multiple], .input-group-sm > .floating-label select.form-control[multiple], .floating-label-sm select.form-control[size]:not([size='1']), .input-group-sm > .floating-label select.form-control[size]:not([size='1']), .floating-label-sm select.custom-select[multiple], .input-group-sm > .floating-label select.custom-select[multiple], .floating-label-sm select.custom-select[size]:not([size='1']), .input-group-sm > .floating-label select.custom-select[size]:not([size='1']), .floating-label-sm textarea.form-control:not([rows='1']), .input-group-sm > .floating-label textarea.form-control:not([rows='1']) {
  padding: 0.75rem 0.75rem calc(0.75rem - 1px);
}

.floating-label.textfield-box {
  padding-top: 0;
}

.floating-label.textfield-box.has-value label, .floating-label.textfield-box.has-value legend,
.floating-label.textfield-box.is-focused label,
.floating-label.textfield-box.is-focused legend {
  line-height: 1;
  top: 0.5rem;
}

.floating-label.textfield-box label, .floating-label.textfield-box legend {
  color: rgba(0, 0, 0, 0.6);
  top: 1rem;
  left: 1rem;
}

.floating-label.textfield-box .form-control, .floating-label.textfield-box .custom-select, .floating-label.textfield-box .form-control-file,
.floating-label.textfield-box select.form-control[multiple],
.floating-label.textfield-box select.form-control[size]:not([size='1']),
.floating-label.textfield-box select.custom-select[multiple],
.floating-label.textfield-box select.custom-select[size]:not([size='1']),
.floating-label.textfield-box textarea.form-control:not([rows='1']) {
  padding-top: 1.5rem;
}

.floating-label.textfield-box .form-control, .floating-label.textfield-box .custom-select, .floating-label.textfield-box .form-control-file {
  padding-bottom: calc(0.5rem - 1px);
}


.floating-label.is-focused label,
.floating-label.is-focused legend {
  color: rgba(98, 0, 238, 0.87);
}

.floating-label-lg.textfield-box label, .input-group-lg > .textfield-box.floating-label label, .floating-label-lg.textfield-box legend, .input-group-lg > .textfield-box.floating-label legend {
  top: 0.875rem;
  left: 1rem;
}

.floating-label-lg.textfield-box .form-control, .input-group-lg > .textfield-box.floating-label .form-control, .floating-label-lg.textfield-box .custom-select, .input-group-lg > .textfield-box.floating-label .custom-select, .floating-label-lg.textfield-box .form-control-file, .input-group-lg > .textfield-box.floating-label .form-control-file,
.floating-label-lg.textfield-box select.form-control[multiple],
.input-group-lg > .textfield-box.floating-label select.form-control[multiple],
.floating-label-lg.textfield-box select.form-control[size]:not([size='1']),
.input-group-lg > .textfield-box.floating-label select.form-control[size]:not([size='1']),
.floating-label-lg.textfield-box select.custom-select[multiple],
.input-group-lg > .textfield-box.floating-label select.custom-select[multiple],
.floating-label-lg.textfield-box select.custom-select[size]:not([size='1']),
.input-group-lg > .textfield-box.floating-label select.custom-select[size]:not([size='1']),
.floating-label-lg.textfield-box textarea.form-control:not([rows='1']),
.input-group-lg > .textfield-box.floating-label textarea.form-control:not([rows='1']) {
  padding-top: 1.249999rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.floating-label-lg.textfield-box select.form-control, .input-group-lg > .textfield-box.floating-label select.form-control, .floating-label-lg.textfield-box select.custom-select, .input-group-lg > .textfield-box.floating-label select.custom-select {
  padding-right: 1.5em;
}

.floating-label-sm.textfield-box label, .input-group-sm > .textfield-box.floating-label label, .floating-label-sm.textfield-box legend, .input-group-sm > .textfield-box.floating-label legend {
  top: 0.75rem;
  left: 0.75rem;
}

.floating-label-sm.textfield-box .form-control, .input-group-sm > .textfield-box.floating-label .form-control, .floating-label-sm.textfield-box .custom-select, .input-group-sm > .textfield-box.floating-label .custom-select, .floating-label-sm.textfield-box .form-control-file, .input-group-sm > .textfield-box.floating-label .form-control-file,
.floating-label-sm.textfield-box select.form-control[multiple],
.input-group-sm > .textfield-box.floating-label select.form-control[multiple],
.floating-label-sm.textfield-box select.form-control[size]:not([size='1']),
.input-group-sm > .textfield-box.floating-label select.form-control[size]:not([size='1']),
.floating-label-sm.textfield-box select.custom-select[multiple],
.input-group-sm > .textfield-box.floating-label select.custom-select[multiple],
.floating-label-sm.textfield-box select.custom-select[size]:not([size='1']),
.input-group-sm > .textfield-box.floating-label select.custom-select[size]:not([size='1']),
.floating-label-sm.textfield-box textarea.form-control:not([rows='1']),
.input-group-sm > .textfield-box.floating-label textarea.form-control:not([rows='1']) {
  padding-top: 1.0rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.floating-label-sm.textfield-box select.form-control, .input-group-sm > .textfield-box.floating-label select.form-control, .floating-label-sm.textfield-box select.custom-select, .input-group-sm > .textfield-box.floating-label select.custom-select {
  padding-right: 1.5em;
}

.floating-label.form-ripple::before {
  top: calc(3rem - 2px);
}

.floating-label-lg.form-ripple::before, .input-group-lg > .form-ripple.floating-label::before {
  top: calc(4.5rem - 2px);
}

.floating-label-sm.form-ripple::before, .input-group-sm > .form-ripple.floating-label::before {
  top: calc(2.75rem - 2px);
}

.input-group {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .floating-label,
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-control-file,
.input-group > .textfield-box {
  flex: 1 1 auto;
  margin-bottom: 0;
  min-width: 0;
  position: relative;
  width: 1%;
}

.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .floating-label,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .form-select,
.input-group > .custom-file + .form-control-file,
.input-group > .custom-file + .textfield-box,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .floating-label,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .form-select,
.input-group > .custom-select + .form-control-file,
.input-group > .custom-select + .textfield-box,
.input-group > .floating-label + .custom-file,
.input-group > .floating-label + .custom-select,
.input-group > .floating-label + .floating-label,
.input-group > .floating-label + .form-control,
.input-group > .floating-label + .form-select,
.input-group > .floating-label + .form-control-file,
.input-group > .floating-label + .textfield-box,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .floating-label,
.input-group > .form-control + .form-control,
.input-group > .form-control + .form-select,
.input-group > .form-control + .form-control-file,
.input-group > .form-control + .textfield-box,
.input-group > .form-select + .custom-file,
.input-group > .form-select + .custom-select,
.input-group > .form-select + .floating-label,
.input-group > .form-select + .form-control,
.input-group > .form-select + .form-select,
.input-group > .form-select + .form-control-file,
.input-group > .form-select + .textfield-box,
.input-group > .form-control-file + .custom-file,
.input-group > .form-control-file + .custom-select,
.input-group > .form-control-file + .floating-label,
.input-group > .form-control-file + .form-control,
.input-group > .form-control-file + .form-select,
.input-group > .form-control-file + .form-control-file,
.input-group > .form-control-file + .textfield-box,
.input-group > .textfield-box + .custom-file,
.input-group > .textfield-box + .custom-select,
.input-group > .textfield-box + .floating-label,
.input-group > .textfield-box + .form-control,
.input-group > .textfield-box + .form-select,
.input-group > .textfield-box + .form-control-file,
.input-group > .textfield-box + .textfield-box {
  margin-left: 1rem;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group-append,
.input-group-prepend {
  align-items: center;
  display: flex;
  justify-content: center;
}

.input-group-append .btn, .input-group-append .card-link,
.input-group-prepend .btn,
.input-group-prepend .card-link {
  min-width: 0;
  position: relative;
  z-index: 2;
}

.input-group-append .btn:focus, .input-group-append .card-link:focus,
.input-group-prepend .btn:focus,
.input-group-prepend .card-link:focus {
  z-index: 3;
}

.input-group-append .btn + .btn, .input-group-append .card-link + .btn, .input-group-append .btn + .card-link, .input-group-append .card-link + .card-link,
.input-group-append .btn + .input-group-text,
.input-group-append .card-link + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .card-link,
.input-group-prepend .btn + .btn,
.input-group-prepend .card-link + .btn,
.input-group-prepend .btn + .card-link,
.input-group-prepend .card-link + .card-link,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .card-link + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .card-link {
  margin-left: -1px;
}

.input-group-append {
  margin-left: 1rem;
}

.input-group-prepend {
  margin-right: 1rem;
}

.input-group-text {
  align-items: center;
  color: inherit;
  display: flex;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0;
  white-space: nowrap;
}

.input-group-text + .input-group-text {
  margin-left: 0.5rem;
}

.input-group > .input-group-append > .btn:not(:first-of-type), .input-group > .input-group-append > .card-link:not(:first-of-type),
.input-group > .input-group-prepend > .btn:not(:first-of-type),
.input-group > .input-group-prepend > .card-link:not(:first-of-type) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .input-group-append > .btn:not(:last-of-type), .input-group > .input-group-append > .card-link:not(:last-of-type),
.input-group > .input-group-prepend > .btn:not(:last-of-type),
.input-group > .input-group-prepend > .card-link:not(:last-of-type) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-icon {
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1);
  color: rgba(0, 0, 0, 0.42);
  fill: currentColor;
  font-size: 1rem;
  margin-right: 1rem;
  order: -1;
}

@media (min-width: 576px) {
  .input-group-icon {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .input-group-icon {
    transition-duration: 280ms;
  }
}

.input-group-icon:hover {
  color: rgba(0, 0, 0, 0.87);
}

.floating-label.is-focused ~ .input-group-icon,
.form-control:focus ~ .input-group-icon {
  color: #6200ee;
}

.floating-label:not(.textfield-box) ~ .input-group-icon {
  margin-top: 0.75rem;
}

.input-group-lg > .floating-label.has-value label, .input-group-lg > .floating-label.has-value legend,
.input-group-lg > .floating-label.is-focused label,
.input-group-lg > .floating-label.is-focused legend {
  top: 0;
}

.input-group-lg > .floating-label.textfield-box.has-value label, .input-group-lg > .floating-label.textfield-box.has-value legend,
.input-group-lg > .floating-label.textfield-box.is-focused label,
.input-group-lg > .floating-label.textfield-box.is-focused legend {
  top: 0.5rem;
}

.input-group-lg > .floating-label:not(.textfield-box) ~ .input-group-icon {
  margin-top: 0.875rem;
}

.input-group-lg > .form-control, .input-group-lg > .custom-select, .input-group-lg > .form-control-file {
  font-size: 2.125rem;
  line-height: 1.176471;
  padding: 0.625rem 0 calc(0.625rem - 1px);
}

.input-group-lg > select.form-control, .input-group-lg > select.custom-select {
  padding-right: 1.5em;
}

.input-group-lg > select.form-control[multiple], .input-group-lg > select.form-control[size]:not([size='1']), .input-group-lg > select.custom-select[multiple], .input-group-lg > select.custom-select[size]:not([size='1']), .input-group-lg > textarea.form-control:not([rows='1']) {
  padding: 0.875rem 1rem calc(0.875rem - 1px);
}

.input-group-lg > .form-control[type='file'],
.input-group-lg > .form-control-file {
  max-height: 4.25rem;
}

.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .input-group-text {
  font-size: 2.125rem;
  line-height: 1.176471;
}

.input-group-lg > .input-group-icon {
  font-size: 2.125rem;
  line-height: 1.176471;
}

.input-group-sm > .floating-label.has-value label, .input-group-sm > .floating-label.has-value legend,
.input-group-sm > .floating-label.is-focused label,
.input-group-sm > .floating-label.is-focused legend {
  top: 0;
}

.input-group-sm > .floating-label.textfield-box.has-value label, .input-group-sm > .floating-label.textfield-box.has-value legend,
.input-group-sm > .floating-label.textfield-box.is-focused label,
.input-group-sm > .floating-label.textfield-box.is-focused legend {
  top: 0.5rem;
}

.input-group-sm > .floating-label:not(.textfield-box) ~ .input-group-icon {
  margin-top: 0.625rem;
}

.input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .form-control-file {
  font-size: 0.8125rem;
  line-height: 1.538462;
  padding: 0.375rem 0 calc(0.375rem - 1px);
}

.input-group-sm > select.form-control, .input-group-sm > select.custom-select {
  padding-right: 1.5em;
}

.input-group-sm > select.form-control[multiple], .input-group-sm > select.form-control[size]:not([size='1']), .input-group-sm > select.custom-select[multiple], .input-group-sm > select.custom-select[size]:not([size='1']), .input-group-sm > textarea.form-control:not([rows='1']) {
  padding: 0.75rem 0.75rem calc(0.75rem - 1px);
}

.input-group-sm > .form-control[type='file'],
.input-group-sm > .form-control-file {
  max-height: 2.75rem;
}

.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .input-group-text {
  font-size: 0.8125rem;
  line-height: 1.538462;
}

.input-group-sm > .input-group-icon {
  font-size: 0.8125rem;
  line-height: 1.538462;
}

.navbar, .navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.navbar {
  color: rgba(0, 0, 0, 0.87);
  min-height: 3.5rem;
  padding: 0.625rem 1rem;
  position: relative;
}

.navbar .form-control {
  border-radius: 4px;
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(0, 0, 0, 0.12);
  border: 0;
  color: inherit;
  opacity: 0.7;
  padding: 0.375rem 1rem;
}

@media (min-width: 576px) {
  .navbar .form-control {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .navbar .form-control {
    transition-duration: 280ms;
  }
}

.navbar .form-control:hover, .navbar .form-control:focus {
  box-shadow: none;
  opacity: 1;
}

.navbar .form-control-lg {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.navbar .form-control-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.navbar .input-group {
  border-radius: 4px;
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(0, 0, 0, 0.12);
  opacity: 0.7;
}

@media (min-width: 576px) {
  .navbar .input-group {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .navbar .input-group {
    transition-duration: 280ms;
  }
}

.navbar .input-group:hover, .navbar .input-group:focus {
  opacity: 1;
}

.navbar .input-group .form-control {
  background-color: transparent;
  opacity: 1;
}

.navbar .input-group .form-control:not(:first-child) {
  padding-left: 0;
}

.navbar .input-group .form-control:not(:last-child) {
  padding-right: 0;
}

.navbar .input-group .input-group-append {
  margin-left: 0;
  min-width: 0;
}

.navbar .input-group .input-group-prepend {
  margin-right: 0;
  min-width: 0;
}

.navbar .input-group .input-group-text {
  margin-right: 1rem;
  margin-left: 1rem;
}

.navbar .input-group-lg > .form-control {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.navbar .input-group-sm > .form-control {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.navbar .nav-tabs {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}

.navbar .nav-tabs .nav-link {
  min-height: 3.5rem;
  padding-top: 1.3125rem;
  padding-bottom: 1.3125rem;
}

.navbar-dark {
  color: white;
}

.navbar-dark .form-control,
.navbar-dark .input-group,
.navbar-dark .navbar-brand::before,
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-toggler::before {
  background-color: rgba(255, 255, 255, 0.12);
}

.navbar-dark .form-control::placeholder,
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.7);
}

.navbar-fixed-bottom,
.navbar-fixed-top {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  position: fixed;
  right: 0;
  left: 0;
  z-index: 40;
}

.navbar-fixed-bottom {
  bottom: 0;
}

.navbar-fixed-top {
  top: 0;
}

.navbar-full {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  z-index: 40;
}

@supports (position: sticky) {
  .navbar-sticky-top {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    position: sticky;
    top: 0;
    z-index: 40;
  }
}

.toolbar-waterfall {
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: background-color, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 40;
}

@media (min-width: 576px) {
  .toolbar-waterfall {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .toolbar-waterfall {
    transition-duration: 280ms;
  }
}

.toolbar-waterfall.waterfall {
  background-color: #6200ee;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  position: fixed;
}

.navbar-brand {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  align-items: center;
  color: inherit;
  display: inline-flex;
  height: 2.25rem;
  position: relative;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus, .navbar-brand:active {
  color: inherit;
  text-decoration: none;
}

.navbar-brand::before {
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(0, 0, 0, 0.12);
  content: '';
  opacity: 0;
  position: absolute;
  top: 0;
  right: -0.5rem;
  bottom: 0;
  left: -0.5rem;
}

@media (min-width: 576px) {
  .navbar-brand::before {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .navbar-brand::before {
    transition-duration: 280ms;
  }
}

.navbar-brand:focus {
  outline: 0;
}

.navbar-brand:focus::before {
  opacity: 1;
}

.navbar-brand:not(:first-child) {
  margin-left: 1rem;
}

.navbar-brand:not(:last-child) {
  margin-right: 1rem;
}

.navbar-text {
  align-items: center;
  color: inherit;
  display: inline-flex;
  flex-wrap: wrap;
  height: 2.25rem;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  /* .dropdown-menu {
    position: static;
    float: none;
  } */
}

.navbar-nav .nav-link {
  border-radius: 4px;
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: background-color, opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  color: inherit;
  font-size: 1rem;
  height: 2.25rem;
  line-height: 1;
  opacity: 0.7;
  padding: 0.625rem 1rem;
}

@media (min-width: 576px) {
  .navbar-nav .nav-link {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .navbar-nav .nav-link {
    transition-duration: 280ms;
  }
}

.navbar-nav .nav-link:hover, .navbar-nav .nav-link:focus, .navbar-nav .nav-link:active {
  background-color: rgba(0, 0, 0, 0.12);
  opacity: 1;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.disabled {
  opacity: 1;
}

.navbar-nav .nav-link.disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.38);
}

.navbar-nav .nav-link:focus {
  outline: 0;
}

.navbar-nav .active > .nav-link,
.navbar-nav .show > .nav-link {
  opacity: 1;
}

.navbar-nav .show > .nav-link {
  background-color: rgba(0, 0, 0, 0.12);
}

.navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-toggler {
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  color: inherit;
  display: inline-flex;
  flex-shrink: 0;
  height: 2.25rem;
  justify-content: center;
  line-height: 1;
  padding: 0;
  position: relative;
  width: 2.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  color: inherit;
  text-decoration: none;
}

.navbar-toggler:hover::before, .navbar-toggler:focus::before {
  opacity: 1;
}

.navbar-toggler::before {
  /* transition: $transition-duration-mobile;
  transition-property: $property;
  transition-timing-function: $transition-timing-function-standard; */
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  content: '';
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (min-width: 576px) {
  .navbar-toggler::before {
    transition-duration: 390ms;
  }
}

@media (min-width: 992px) {
  .navbar-toggler::before {
    transition-duration: 280ms;
  }
}

.navbar-toggler:focus {
  outline: 0;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  font-size: 1.5rem;
  line-height: 1.5rem;
  vertical-align: -0.362637em;
  font-family: 'Material Icons';
  font-family: var(--font-family-material-icons);
  font-feature-settings: 'liga';
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  /* display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; */
  display: inline-block;
}

.navbar-toggler-icon::before {
  content: "menu";
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

/* .navbar-light {
  .navbar-brand {
    color: $navbar-light-brand-color;

    @include hover-focus {
      color: $navbar-light-brand-hover-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-light-color;

      @include hover-focus {
        color: $navbar-light-hover-color;
      }

      &.disabled {
        color: $navbar-light-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-light-active-color;
    }
  }

  .navbar-toggler {
    color: $navbar-light-color;
    border-color: $navbar-light-toggler-border-color;
  }

  .navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-bg;
  }

  .navbar-text {
    color: $navbar-light-color;
    a {
      color: $navbar-light-active-color;

      @include hover-focus {
        color: $navbar-light-active-color;
      }
    }
  }
}

// White links against a dark background
.navbar-dark {
  .navbar-brand {
    color: $navbar-dark-brand-color;

    @include hover-focus {
      color: $navbar-dark-brand-hover-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $navbar-dark-color;

      @include hover-focus {
        color: $navbar-dark-hover-color;
      }

      &.disabled {
        color: $navbar-dark-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-dark-active-color;
    }
  }

  .navbar-toggler {
    color: $navbar-dark-color;
    border-color: $navbar-dark-toggler-border-color;
  }

  .navbar-toggler-icon {
    background-image: $navbar-dark-toggler-icon-bg;
  }

  .navbar-text {
    color: $navbar-dark-color;
    a {
      color: $navbar-dark-active-color;

      @include hover-focus {
        color: $navbar-dark-active-color;
      }
    }
  }
} */
.tooltip {
  font-size: 0.875rem;
  font-weight: 500;
  margin: 1.5rem;
  max-width: 250px;
  opacity: 0;
  position: absolute;
  z-index: 240;
}

@media (min-width: 992px) {
  .tooltip {
    font-size: 0.625rem;
    margin: 0.875rem;
  }
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip.show .tooltip-inner {
  transform: scale(1);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);
}

.tooltip-inner {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #6d6d6d;
  border-radius: 4px;
  color: white;
  height: 2rem;
  letter-spacing: 0.033333em;
  line-height: 2rem;
  padding: 0 1rem;
  transform: scale(0.87);
  transform-origin: center top;
  transition: transform 75ms cubic-bezier(0.4, 0, 1, 1);
}

@media (min-width: 992px) {
  .tooltip-inner {
    height: 1.5rem;
    line-height: 1.5rem;
    padding: 0 0.5rem;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-dark-1 {
  background-color: #000000 !important;
}

.bg-dark-2 {
  background-color: #212121 !important;
}

.bg-dark-3 {
  background-color: #303030 !important;
}

.bg-dark-4 {
  background-color: #3c3c46 !important;
}

.bg-light-1 {
  background-color: #e0e0e0 !important;
}

.bg-light-2 {
  background-color: #f5f5f5 !important;
}

.bg-light-3 {
  background-color: #fafafa !important;
}

.bg-light-4 {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-white {
  background-color: #ffffff !important;
}

a.bg-primary:hover, a.bg-primary:focus, a.bg-primary:active {
  background-color: #3700b3 !important;
}

.bg-primary {
  background-color: #6200ee !important;
}

a.bg-secondary:hover, a.bg-secondary:focus, a.bg-secondary:active {
  background-color: #018786 !important;
}

.bg-secondary {
  background-color: #03dac6 !important;
}

a.bg-danger:hover, a.bg-danger:focus, a.bg-danger:active {
  background-color: #d32f2f !important;
}

.bg-danger {
  background-color: #b00020 !important;
}

a.bg-info:hover, a.bg-info:focus, a.bg-info:active {
  background-color: #021aee !important;
}

.bg-info {
  background-color: #714cfe !important;
}

a.bg-success:hover, a.bg-success:focus, a.bg-success:active {
  background-color: #41c300 !important;
}

.bg-success {
  background-color: #61d800 !important;
}

a.bg-warning:hover, a.bg-warning:focus, a.bg-warning:active {
  background-color: #ee6002 !important;
}

.bg-warning {
  background-color: #ff8d00 !important;
}

a.bg-dark:hover, a.bg-dark:focus, a.bg-dark:active {
  background-color: #212121 !important;
}

.bg-dark {
  background-color: #424242 !important;
}

a.bg-light:hover, a.bg-light:focus, a.bg-light:active {
  background-color: #e0e0e0 !important;
}

.bg-light {
  background-color: #f5f5f5 !important;
}

.bg-primary-dark {
  background-color: #3700b3 !important;
}

.bg-primary-light {
  background-color: #bb86fc !important;
}

.bg-secondary-dark {
  background-color: #018786 !important;
}

.bg-secondary-light {
  background-color: #66fff8 !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #6200ee !important;
}

.border-secondary {
  border-color: #03dac6 !important;
}

.border-danger {
  border-color: #b00020 !important;
}

.border-info {
  border-color: #714cfe !important;
}

.border-success {
  border-color: #61d800 !important;
}

.border-warning {
  border-color: #ff8d00 !important;
}

.border-dark {
  border-color: #424242 !important;
}

.border-light {
  border-color: #f5f5f5 !important;
}

.border-black {
  border-color: #000000 !important;
}

.border-black-primary {
  border-color: rgba(0, 0, 0, 0.87) !important;
}

.border-black-secondary {
  border-color: rgba(0, 0, 0, 0.54) !important;
}

.border-black-hint {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.border-black-divider {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.border-white {
  border-color: #ffffff !important;
}

.border-white-primary {
  border-color: white !important;
}

.border-white-secondary {
  border-color: rgba(255, 255, 255, 0.7) !important;
}

.border-white-hint {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.border-white-divider {
  border-color: rgba(255, 255, 255, 0.12) !important;
}

.rounded-sm {
  border-radius: 2px !important;
}

.rounded {
  border-radius: 4px !important;
}

.rounded-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.rounded-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rounded-bottom {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rounded-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rounded-lg {
  border-radius: 16px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  clear: both;
  content: '';
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}

.embed-responsive::before {
  content: '';
  display: block;
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  border: 0;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 40;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 40;
  }
}

[data-ripplet]:not(:disabled):not(.disabled) {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static;
  white-space: normal;
  width: auto;
}

.shadow-sm {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}

.shadow {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2) !important;
}

.shadow-lg {
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.shadow-24 {
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2) !important;
}

.shadow-16 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
}

.shadow-12 {
  box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2) !important;
}

.shadow-8 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2) !important;
}

.shadow-6 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2) !important;
}

.shadow-4 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.shadow-2 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}

.shadow-1 {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.2) !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  background-color: rgba(0, 0, 0, 0);
  content: '';
  pointer-events: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.text-monospace {
  font-family: "Roboto Mono", "SFMono-Regular", "Menlo", "Monaco", "Consolas", "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-truncate-flex {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-weight-bolder {
  font-weight: 600 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-black {
  color: #000000 !important;
}

.text-black-primary {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-black-secondary {
  color: rgba(0, 0, 0, 0.54) !important;
}

.text-black-hint {
  color: rgba(0, 0, 0, 0.38) !important;
}

.text-black-divider {
  color: rgba(0, 0, 0, 0.12) !important;
}

.text-white {
  color: #ffffff !important;
}

.text-white-primary {
  color: white !important;
}

.text-white-secondary {
  color: rgba(255, 255, 255, 0.7) !important;
}

.text-white-hint {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-white-divider {
  color: rgba(255, 255, 255, 0.12) !important;
}

.text-muted {
  color: rgba(0, 0, 0, 0.38) !important;
}

a.text-primary:hover, a.text-primary:focus, a.text-primary:active {
  color: #3700b3 !important;
}

.text-primary {
  color: #6200ee !important;
}

a.text-secondary:hover, a.text-secondary:focus, a.text-secondary:active {
  color: #018786 !important;
}

.text-secondary {
  color: #03dac6 !important;
}

a.text-danger:hover, a.text-danger:focus, a.text-danger:active {
  color: #d32f2f !important;
}

.text-danger {
  color: #b00020 !important;
}

a.text-info:hover, a.text-info:focus, a.text-info:active {
  color: #021aee !important;
}

.text-info {
  color: #714cfe !important;
}

a.text-success:hover, a.text-success:focus, a.text-success:active {
  color: #41c300 !important;
}

.text-success {
  color: #61d800 !important;
}

a.text-warning:hover, a.text-warning:focus, a.text-warning:active {
  color: #ee6002 !important;
}

.text-warning {
  color: #ff8d00 !important;
}

a.text-dark:hover, a.text-dark:focus, a.text-dark:active {
  color: #212121 !important;
}

.text-dark {
  color: #424242 !important;
}

a.text-light:hover, a.text-light:focus, a.text-light:active {
  color: #e0e0e0 !important;
}

.text-light {
  color: #f5f5f5 !important;
}

.text-body {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.54) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  background-color: transparent;
  border: 0;
  color: transparent;
  font: 0/0 a;
  text-shadow: none;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

[class*='material-icons'] {
  font-size: 1.5rem;
  line-height: 1.5rem;
  vertical-align: -0.362637em;
  font-family: 'Material Icons';
  font-family: var(--font-family-material-icons);
  font-feature-settings: 'liga';
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}

[class*='material-icons'].md-18 {
  font-size: 1.125rem;
}

[class*='material-icons'].md-36 {
  font-size: 2.25rem;
}

[class*='material-icons'].md-48 {
  font-size: 3rem;
}

.material-icons-outlined {
  font-family: "Material Icons Outlined", var(--font-family-material-icons);
}

.material-icons-round {
  font-family: "Material Icons Round", var(--font-family-material-icons);
}

.material-icons-two-tone {
  font-family: "Material Icons Two Tone", var(--font-family-material-icons);
}

.material-icons-sharp {
  font-family: "Material Icons Sharp", var(--font-family-material-icons);
}

.material-icons-inline {
  font-size: inherit;
  line-height: 1;
}

@media (min-width: 992px) {
  ::-webkit-scrollbar {
    height: 8px;
    width: 12px;
  }
  ::-webkit-scrollbar-button {
    background-color: transparent;
    display: block;
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-track-piece {
    background: transparent none;
    border: solid 4px transparent;
    border-right-width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border: solid 0 transparent;
    border-radius: 5px;
    border-top-right-radius: 9px 5px;
    border-bottom-right-radius: 9px 5px;
    border-right-width: 4px;
    box-shadow: inset 0 0 0 6px #e0dfe1;
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    box-shadow: inset 0 0 0 6px rgba(224, 223, 225, 0.5);
  }
}

body * {
  scrollbar-color: #e0dfe1 transparent;
  scrollbar-width: thin;
}

::-webkit-search-cancel-button,
::-webkit-clear-button {
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;charset=utf8,%3csvg fill="%23000000" fill-opacity="0.54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3e%3cpath d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"/%3e%3cpath d="M0 0h24v24H0z" fill="none"/%3e%3c/svg%3e');
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  height: 1.2em;
  margin: 0 0 0 1.5rem;
  padding: 2px 3px;
  width: 1.2em;
}

::-webkit-calendar-picker-indicator {
  cursor: pointer;
  opacity: 0.6;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
}

@media print {
  *,
  *::before,
  *::after {
    box-shadow: none !important;
    text-shadow: none !important;
  }
  abbr[title]::after {
    content: ' ("attr(title) ") ';
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid rgba(0, 0, 0, 0.12);
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-inside: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .fab-actions,
  .navbar,
  .tooltip {
    display: none;
  }
  .badge {
    border: 1px solid #000000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #ffffff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e1e1e1;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #e1e1e1;
  }
  .table .thead-dark th {
    border-color: #e1e1e1;
    color: inherit;
  }
  .card {
    border: 1px solid #e1e1e1;
    page-break-inside: avoid;
  }
  .dropdown-menu {
    border: 1px solid #e1e1e1;
  }
  .list-group-item {
    border: 1px solid #e1e1e1;
    margin-bottom: -1px;
  }
  .list-group-item:last-child {
    margin-bottom: 0;
  }
  .list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
  }
  .list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0;
  }
  .list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
  }
  .nav-tabs {
    border-bottom: 1px solid #e1e1e1;
  }
  .popover {
    border: 1px solid #e1e1e1;
  }
}

/*# sourceMappingURL=material.css.map */